import React, { useEffect, useMemo, useState } from 'react';
import { Button, PageHeader, Table } from 'antd';
import SearchForm from '../../../components/datalist/SearchForm';
import { restApi } from '../../../apis';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import qs from 'query-string';
import { getClientWidth } from '../../../common/utils';
import dayjs from 'dayjs';

const SNS_LABEL = {
  kakao: '카카오',
  google: '구글',
  naver: '네이버',
};

const LIMIT = 50;
const COLUMNS = [
  {
    title: '번호',
    dataIndex: 'id',
    align: 'center',
    width: 90,
    sorter: true,
  },
  {
    title: '가입종류',
    dataIndex: 'signname',
    width: 100,
    render: (d) => {
      return SNS_LABEL[d.substring(d.indexOf('@') + 1)] ?? d.substring(d.indexOf('@') + 1);
    },
  },
  {
    title: '닉네임',
    dataIndex: 'nickname',
    width: 160,
  },
  {
    title: '이메일',
    dataIndex: 'email',
    minWidth: 200,
  },
  {
    title: '견적생성수',
    dataIndex: 'estimate_count',
    align: 'center',
    width: 90,
    sorter: true,
  },
  {
    title: '의뢰수',
    dataIndex: 'construct_count',
    align: 'center',
    width: 90,
    sorter: true,
  },
  {
    title: '마지막접속',
    dataIndex: 'last_used_at',
    align: 'center',
    width: 160,
    render: (d) => dayjs(d).format('YYYY-MM-DD HH:mm'),
    sorter: true,
  },
  {
    title: '가입일',
    dataIndex: 'created_at',
    align: 'center',
    width: 160,
    render: (d) => dayjs(d).format('YYYY-MM-DD HH:mm'),
    sorter: true,
  },
];

const AccountsUsersIndex = () => {
  const location = useLocation();
  const query = React.useMemo(() => qs.parse(location?.search), [location?.search]);
  const [page, setPage] = useState(parseInt(qs.parse(location.search)?.page || '0', 10));
  const [data, setData] = React.useState();
  const [pageSize, setPageSize] = useState(20);
  const navigate = useNavigate();
  // const [extra, setExtra] = useState();

  // query 바뀔 때 마다 데이터 조회
  const loadData = React.useCallback(async () => {
    const params = {
      ...query,
      limit: pageSize,
    };
    const { data } = await restApi.get('/accounts', { params });
    setData(data);
  }, [query, pageSize]);

  React.useEffect(() => {
    loadData().catch(console.warn);
  }, [loadData]);
  console.log('data', data);
  const [columns, tableWidth] = useMemo(() => {
    const o = [...COLUMNS];

    const width = o.reduce((a, c) => a + (c.width || c.minWidth || 300), 0);
    return [o, width];
  }, []);
  const searchField = [
    {
      width: 0.5,
      label: '일반검색',
      type: 'input',
      key: 'keyword',
    },
  ];

  const [tableScroll, setTableScroll] = useState();
  useEffect(() => {
    const handle = () => {
      if (tableWidth < getClientWidth()) {
        setTableScroll(undefined);
      } else {
        setTableScroll({ x: tableWidth });
      }
    };

    handle();
  }, [tableWidth]);

  // 테이블 페이지네이션
  const [tableHeight, setTableHeight] = useState(document.body.clientHeight - 60 - 180);
  useEffect(() => {
    const fn = () => {
      setTableHeight(document.body.clientHeight - 60 - 180);
    };
    window.addEventListener('resize', fn);
    return () => {
      window.removeEventListener('resize', fn);
    };
  }, []);

  const onRow = (record, rowIndex) => {
    return {
      onClick: (event) => {
        navigate(`/accounts/users/${record.id}`);
      },
    };
  };

  return (
    <div>
      <PageHeader className="site-page-header" title="고객회원" subTitle={'고객 관리'} />
      <SearchForm fields={searchField} extra={{ page }} />
      <Table
        className="ant-table-pointer"
        dataSource={data?.items ?? []}
        columns={columns}
        scroll={{ y: tableHeight }}
        pagination={{
          current: page + 1,
          total: data?.total_elements,
          pageSize: pageSize,
          pageSizeOptions: data?.total_elements >= 500 ? ['10', '20', '30', '50', '100'] : ['10', '20', '30', '50'],
          onShowSizeChange: (current, size) => setPageSize(size),
          showSizeChanger: true,
        }}
        onChange={(e) => {
          setPage(e.current - 1);
        }}
        onRow={onRow}
      />
    </div>
  );
};

export default AccountsUsersIndex;
