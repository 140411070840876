import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import React, { useEffect, useState } from 'react';
import { Button, Descriptions, Input, PageHeader, Select, Space, Table, Upload } from 'antd';
import { getAPIHost, restApi } from '../../../apis';
import { UploadOutlined } from '@ant-design/icons';
import { useParams } from 'react-router';
import { parseImageUri } from '../../../common/utils';
import produce from 'immer';
import SingleImageUpload from '../../../components/SingleImageUpload';

const ContentSectionForm = (props) => {
  const route = useParams();
  // console.log(route);
  const [data, setData] = useState({
    background: undefined,
    title: '',
    title_color: undefined,
    desc: '',
    desc_color: undefined,
    style_type: 'large',
  });

  const [items, setItems] = useState();

  useEffect(() => {
    const loadData = async () => {
      const { data } = await restApi.get(`/homes/sections/${route.id}`);
      setData(data);
      setItems(data.items);
    };
    loadData().catch(console.warn);
  }, [route.id]);

  const handleCreatePost = async () => {
    if (route.id === 'create') {
      await restApi.post(`/homes/sections`, {
        ...data,
      });
    } else {
      await restApi.put(`/homes/sections/${route.id}`, {
        ...data,
      });
    }
    alert('저장되었습니다.');
    window.location.replace('/contents/sections');
  };

  const columns = [
    {
      title: '이미지',
      dataIndex: 'image',
      key: 'image',
      width: 200,
      render: (data, row, i) => {
        return (
          <SingleImageUpload
            id={data}
            onChange={(v) => {
              setItems(
                produce((draft) => {
                  draft[i].image = v;
                }),
              );
            }}
          />
        );
      },
    },
    {
      title: '제목',
      dataIndex: 'title',
      key: 'title',
      width: 200,
      render: (data, row, i) => {
        return (
          <Input
            value={data}
            onChange={(e) => {
              const v = e.target.value;
              setItems(
                produce((draft) => {
                  draft[i].title = v;
                }),
              );
            }}
          />
        );
      },
    },
    {
      title: '내용',
      dataIndex: 'desc',
      key: 'desc',
      render: (data, row, i) => {
        return (
          <Input
            value={data}
            onChange={(e) => {
              const v = e.target.value;
              setItems(
                produce((draft) => {
                  draft[i].desc = v;
                }),
              );
            }}
          />
        );
      },
    },
    {
      title: '링크',
      dataIndex: 'link',
      key: 'link',
      width: 300,
      render: (data, row, i) => {
        return (
          <Input
            value={data}
            onChange={(e) => {
              const v = e.target.value;
              setItems(
                produce((draft) => {
                  draft[i].link = v;
                }),
              );
            }}
          />
        );
      },
    },
    {
      title: '순서',
      dataIndex: 'order_index',
      key: 'order_index',
      width: 100,
      render: (data, row, i) => {
        return (
          <Input
            value={data}
            onChange={(e) => {
              const v = e.target.value;
              setItems(
                produce((draft) => {
                  draft[i].order_index = v;
                }),
              );
            }}
          />
        );
      },
    },
    {
      title: '관리',
      dataIndex: 'id',
      key: 'id',
      width: 130,
      align: 'center',
      render: (data, row, i) => {
        if (!row.id) {
          return (
            <>
              {' '}
              <Button
                size={'small'}
                onClick={async () => {
                  const { data } = await restApi.post(`/homes/sections/${route.id}/items`, {
                    ...row,
                  });
                  setItems(
                    produce((draft) => {
                      draft[i].id = data.id;
                    }),
                  );
                }}
              >
                등록
              </Button>
              <Button
                size={'small'}
                onClick={async () => {
                  if (!window.confirm('정말 삭제 하시겠습니까?')) return;
                  setItems(
                    produce((draft) => {
                      draft.splice(i, 1);
                    }),
                  );
                }}
              >
                삭제
              </Button>
            </>
          );
        }
        return (
          <>
            <Button
              size={'small'}
              onClick={async () => {
                await restApi.put(`/homes/sections/${route.id}/items/${row.id}`, {
                  ...row,
                });
              }}
            >
              수정
            </Button>

            <Button
              size={'small'}
              onClick={async () => {
                if (!window.confirm('정말 삭제 하시겠습니까?')) return;
                await restApi.delete(`/homes/sections/${route.id}/items/${row.id}`);
                setItems(
                  produce((draft) => {
                    draft.splice(i, 1);
                  }),
                );
              }}
            >
              삭제
            </Button>
          </>
        );
      },
    },
  ];

  const handleDeletePost = async () => {
    if (!window.confirm('정말 삭제하시겠습니까?')) return;

    await restApi.delete(`/homes/sections/${route.id}`);
    alert('삭제되었습니다.');
    window.location.replace('/contents/sections');
  };

  return (
    <div>
      <PageHeader className="site-page-header" title={route.id === 'create' ? '섹션 작성' : '섹션 수정'} />
      <Descriptions bordered>
        <Descriptions.Item label={'이미지'} span={1} className="nopadding">
          {!!data.background && (
            <Button
              onClick={() => {
                setData((x) => ({ ...x, background: 0 }));
              }}
            >
              이미지 삭제
            </Button>
          )}
          <Upload
            action={`${getAPIHost()}/files`}
            method={'PUT'}
            name={'filedata'}
            onChange={(info) => {
              if (info.file.status !== 'done') return;
              setData((x) => ({ ...x, background: info.file.response.id }));
            }}
          >
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
          {!!data.background && <img src={parseImageUri(data.background)} style={{ maxWidth: 200 }} />}
        </Descriptions.Item>
        <Descriptions.Item label={'속성사이즈'} span={1} className="nopadding">
          <Select
            value={data.style_type}
            style={{ width: '100%' }}
            onChange={(style_type) => setData((v) => ({ ...v, style_type }))}
          >
            <Select.Option value={'large'}>크게</Select.Option>
            <Select.Option value={'medium'}>중간</Select.Option>
            <Select.Option value={'small'}>작게</Select.Option>
            <Select.Option value={'xsmall'}>아이콘형태</Select.Option>
            <Select.Option value={'banner'}>배너</Select.Option>
          </Select>
        </Descriptions.Item>
        <Descriptions.Item label={'순서'} span={1} className="nopadding">
          <Input
            value={data.order_index}
            onChange={(e) => {
              const order_index = e.target.value;
              setData((x) => ({ ...x, order_index }));
            }}
            bordered={false}
            placeholder="0"
            style={{ padding: '10px 20px' }}
          />
        </Descriptions.Item>
        <Descriptions.Item label={'제목'} span={3} className="nopadding">
          <Input
            value={data.title}
            onChange={(e) => {
              const title = e.target.value;
              setData((x) => ({ ...x, title }));
            }}
            bordered={false}
            placeholder="제목을 입력해주세요"
            style={{ padding: '10px 20px' }}
          />
        </Descriptions.Item>
        <Descriptions.Item label={'제목 색상'} span={1} className="nopadding">
          <Input
            value={data.title_color}
            onChange={(e) => {
              const title_color = e.target.value;
              setData((x) => ({ ...x, title_color }));
            }}
            bordered={false}
            placeholder="#333"
            style={{ padding: '10px 20px' }}
          />
        </Descriptions.Item>
        <Descriptions.Item label={'제목 글씨크기'} span={1} className="nopadding">
          <Input
            value={data.title_size}
            onChange={(e) => {
              const title_size = e.target.value;
              setData((x) => ({ ...x, title_size }));
            }}
            bordered={false}
            placeholder="20"
            style={{ padding: '10px 20px' }}
          />
        </Descriptions.Item>
        <Descriptions.Item label={'제목 줄간격'} span={1} className="nopadding">
          <Input
            value={data.title_line}
            onChange={(e) => {
              const title_line = e.target.value;
              setData((x) => ({ ...x, title_line }));
            }}
            bordered={false}
            placeholder="24"
            style={{ padding: '10px 20px' }}
          />
        </Descriptions.Item>
        <Descriptions.Item label={'내용'} span={3} className="nopadding">
          <Input
            value={data.desc}
            onChange={(e) => {
              const desc = e.target.value;
              setData((x) => ({ ...x, desc }));
            }}
            bordered={false}
            placeholder="내용을 입력해주세요"
            style={{ padding: '10px 20px' }}
          />
        </Descriptions.Item>
        <Descriptions.Item label={'내용 색상'} span={1} className="nopadding">
          <Input
            value={data.desc_color}
            onChange={(e) => {
              const desc_color = e.target.value;
              setData((x) => ({ ...x, desc_color }));
            }}
            bordered={false}
            placeholder="#333"
            style={{ padding: '10px 20px' }}
          />
        </Descriptions.Item>
        <Descriptions.Item label={'내용 글씨크기'} span={1} className="nopadding">
          <Input
            value={data.desc_size}
            onChange={(e) => {
              const desc_size = e.target.value;
              setData((x) => ({ ...x, desc_size }));
            }}
            bordered={false}
            placeholder="14"
            style={{ padding: '10px 20px' }}
          />
        </Descriptions.Item>
        <Descriptions.Item label={'내용 줄간격'} span={1} className="nopadding">
          <Input
            value={data.desc_line}
            onChange={(e) => {
              const desc_line = e.target.value;
              setData((x) => ({ ...x, desc_line }));
            }}
            bordered={false}
            placeholder="18"
            style={{ padding: '10px 20px' }}
          />
        </Descriptions.Item>
        {data.style_type === 'banner' && (
          <Descriptions.Item label={'링크'} span={4} className="nopadding">
            <Input
              value={data.link}
              onChange={(e) => {
                const link = e.target.value;
                setData((x) => ({ ...x, link }));
              }}
              bordered={false}
              placeholder="https://naver.com"
              style={{ padding: '10px 20px' }}
            />
          </Descriptions.Item>
        )}
      </Descriptions>
      <div style={{ justifyContent: 'flex-end', marginTop: 20 }}>
        {/*<Button*/}
        {/*  onClick={handleCancel}*/}
        {/*  size={'large'}*/}
        {/*  className={styles.basicButton}*/}
        {/*  style={{ marginRight: 5, height: 40, width: 100 }}*/}
        {/*>*/}
        {/*  취소*/}
        {/*</Button>*/}
        <Space>
          <Button onClick={handleCreatePost}>저장</Button>
          {!!route?.id && route?.id !== 'create' && (
            <Button danger onClick={handleDeletePost}>
              삭제
            </Button>
          )}
        </Space>
      </div>

      {!!data?.id && data.style_type !== 'banner' && (
        <div style={{ marginTop: 50 }}>
          <div>
            <Button
              onClick={() => {
                setItems(
                  produce((draft) => {
                    draft.push({});
                  }),
                );
              }}
            >
              추가
            </Button>
          </div>

          <div style={{ marginTop: 5 }}>
            <Table columns={columns} dataSource={items} pagination={false} />
          </div>
        </div>
      )}
    </div>
  );
};

export default ContentSectionForm;
