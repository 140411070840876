import React, { useEffect, useMemo, useState } from 'react';
import { Button, PageHeader } from 'antd';
import SearchForm from '../../../components/datalist/SearchForm';
import { restApi } from '../../../apis';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'query-string';
import { comma, getClientWidth } from '../../../common/utils';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { POSITION_LABELS } from '../../../common/define';
import DragSortingTable from '../../../components/DragSortingTable';
import { getEstimateTpls } from '../../../data/common';
import produce from 'immer';
import EstimateTplForm from '../../../components/settings/EstimateTplForm';

const COLUMNS = [
  {
    title: '번호',
    dataIndex: 'id',
    align: 'center',
    width: 90,
    fixed: 'left',
    render: (v, __, index) => {
      return v;
    },
  },
  {
    title: '이름',
    dataIndex: 'name',
    width: 200,
    fixed: 'left',
    render: (v, __, index) => {
      return v;
    },
  },
  {
    title: '반영영역',
    key: 'element_types',
    dataIndex: 'element_types',
    render: (v, r) => {
      return v?.map((d) => POSITION_LABELS[d] ?? d)?.join(', ');
    },
  },
  {
    title: '선택가능여부',
    key: 'selectable',
    dataIndex: 'selectable',
    width: 100,
    align: 'center',
    render: (v, r) => {
      return v ? 'Y' : 'N';
    },
  },
  {
    title: '적용금액',
    dataIndex: 'price',
    width: 180,
    key: 'price',
    align: 'right',
    render: (v, r) => {
      if (r.use_product) return '[제품가격]';
      return comma(v ?? 0);
    },
  },
  {
    title: '방별 기본금액',
    dataIndex: 'extra_price',
    width: 120,
    key: 'extra_price',
    align: 'right',
    render: (v) => {
      return comma(v ?? 0);
    },
  },
  {
    title: '전체 기본금액',
    dataIndex: 'extra_add_price',
    width: 120,
    key: 'extra_add_price',
    align: 'right',
    render: (v) => {
      return comma(v ?? 0);
    },
  },
  {
    title: '단위',
    dataIndex: 'print_mode',
    width: 70,
    key: 'print_mode',
    align: 'center',
  },

  {
    key: 'use_code',
    title: '제품 카테고리',
    dataIndex: 'use_code',
    width: 250,
    render: (v) => {
      return v?.join(', ');
    },
  },

  {
    key: 'use_product_size',
    title: '제품 최대갯수',
    dataIndex: 'use_product_size',
    width: 100,
    align: 'center',
  },
  {
    key: 'proc_code',
    title: '전산코드',
    dataIndex: 'proc_code',
    width: 100,
    align: 'center',
  },
  {
    title: '',
    dataIndex: 'id',
    key: 'more',
    width: 120,
    align: 'center',
    render: (id, r) => {
      return <Button size={'small'}>자세히보기</Button>;
    },
  },
];

const parsePC = (categories, v) => {
  if (!categories) return v;
  let name = '';
  let cs = [...categories];
  for (let i = 1; i < Math.floor(v?.length / 3) + 1; i++) {
    const code = v.substring(0, i * 3);
    const item = cs.find((a) => a.code === code);
    if (item) {
      name += (name !== '' ? '/' : '') + item?.label;
    }
    cs = item?.children ? [...item?.children] : []; //[...item?.children] ?? [];
  }

  return name;
};

const SettingsEstimateTplIndex = () => {
  const location = useLocation();
  const query = React.useMemo(() => qs.parse(location?.search), [location?.search]);
  const dispatch = useDispatch();

  const [data, setData] = React.useState([]);
  const [modify, setModify] = useState();

  const { estimateTpls, productCategories, estimateCategories } = useSelector((s) => s.common, shallowEqual);

  const categories = useMemo(() => {
    return estimateCategories?.map((v) => ({
      label: v.name,
      value: String(v.id),
      children: v?.items,
    }));
  }, [estimateCategories]);

  const sideCategories = useMemo(() => {
    const r = categories?.find((v) => v.value === query.main_category_id);
    if (!r) return [];
    return r.children?.map((v) => ({
      value: String(v.id),
      label: v.name,
    }));
  }, [categories, query.main_category_id]);

  // query 바뀔 때 마다 데이터 조회
  const loadData = React.useCallback(async () => {
    if (!query.main_category_id || !query.side_category_id) {
      setData([]);
    } else {
      const data = estimateTpls?.filter(
        (v) =>
          String(v.main_category.id) === query.main_category_id &&
          String(v.side_category.id) === query.side_category_id,
      );
      setData(data);
    }
  }, [query, estimateTpls]);

  React.useEffect(() => {
    loadData().catch(console.warn);
  }, [loadData]);

  const [columns, tableWidth] = useMemo(() => {
    const o = [...COLUMNS];

    o[8].render = (v) => {
      return v?.map((v) => parsePC(productCategories, v) || v).join(', ');
    };

    o[11].render = (_, r) => {
      return (
        <Button onClick={() => setModify(r)} size={'small'}>
          자세히보기
        </Button>
      );
    };

    const width = o.reduce((a, c) => a + (c.width || c.minWidth || 300), 0);
    return [o, width];
  }, [productCategories]);

  const [loading, setLoading] = useState(false);
  const handleDelete = async () => {
    if (!window.confirm('정말 삭제하시겠습니까?')) return;
    try {
      setLoading(true);
      if (modify?.id) {
        // update
        await restApi.delete(`/estimates/tpls/${modify.id}`);
      }
      await dispatch(getEstimateTpls());
      setModify(undefined);
    } catch (e) {
      alert(e?.response?.data?.message || '서버와 연결이 올바르지 않습니다.');
    }

    setLoading(false);
  };
  const handleSubmit = async () => {
    try {
      setLoading(true);
      if (modify?.id) {
        // update
        await restApi.put(`/estimates/tpls/${modify.id}`, { ...modify, print_field: modify?.calc_fields?.join(' ') });
      } else {
        // create
        await restApi.post(`/estimates/tpls`, {
          ...modify,
          main_category_id: query.main_category_id,
          side_category_id: query.side_category_id,
          print_field: modify?.calc_fields?.join(' '),
        });
      }
      await dispatch(getEstimateTpls());
      setModify(undefined);
    } catch (e) {
      alert(e?.response?.data?.message || '서버와 연결이 올바르지 않습니다.');
    }

    setLoading(false);
  };

  const [tableScroll, setTableScroll] = useState();
  useEffect(() => {
    const handle = () => {
      if (tableWidth < getClientWidth()) {
        setTableScroll(undefined);
      } else {
        setTableScroll({ x: tableWidth });
      }
    };

    handle();
  }, [tableWidth]);

  const handleSort = async (index, afterIndex) => {
    setLoading(true);
    try {
      const items = produce(data, (draft) => {
        const item = draft.splice(index, 1);
        draft.splice(afterIndex, 0, item[0]);
      });
      setData(items);
      await restApi.put(`/estimates/tpls`, { ids: items?.map((v) => v.id) });
    } catch (e) {
      alert(e?.response?.data?.message || '서버와 연결이 올바르지 않습니다.');
    }
    await dispatch(getEstimateTpls());
    setLoading(false);
  };

  const navigate = useNavigate();

  return (
    <div>
      <EstimateTplForm
        modify={modify}
        setModify={setModify}
        loading={loading}
        onDelete={handleDelete}
        onSubmit={handleSubmit}
        mc_id={query.main_category_id}
        sc_id={query.side_category_id}
      />
      <PageHeader className="site-page-header" title="견적 기본템플릿" subTitle={'설정'} />
      <SearchForm
        rightDeco={
          <Button
            onClick={() => {
              setModify({
                selectable: true,
                use_product: false,
                price: 0,
                base_price: 0,
              });
            }}
          >
            등록
          </Button>
        }
        fields={[
          {
            width: 0.5,
            label: '공종',
            type: 'select',
            key: 'main_category_id',
            items: categories,
            submit: true,
          },
          {
            width: 0.5,
            label: '구분',
            type: 'select',
            key: 'side_category_id',
            items: sideCategories,
            disabled: !query.main_category_id,
            submit: true,
          },
        ]}
      />
      <div style={{ position: 'relative' }}>
        <DragSortingTable
          rowKey={({ id }) => id}
          scroll={tableScroll}
          dataSource={data}
          columns={columns}
          pagination={false}
          emptyText={'공종, 구분을 선택해주세요.'}
          onSort={handleSort}
        />
      </div>
    </div>
  );
};

export default SettingsEstimateTplIndex;
