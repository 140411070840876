import React, { useCallback, useEffect, useState } from 'react';
import { Button, Image, PageHeader, Table } from 'antd';
import SearchForm from '../../../components/datalist/SearchForm';
import { getAPIHost, restApi } from '../../../apis';
import { Link, useLocation } from 'react-router-dom';
import qs from 'query-string';
import { POSITION_LABELS } from '../../../common/define';
import moment from 'moment';

const LIMIT = 20;
const CollectionsItemsIndex = (props) => {
  const location = useLocation();
  const [page, setPage] = useState(parseInt(qs.parse(location.search)?.page || '0', 10));
  const [data, setData] = useState();
  const loadData = useCallback(async () => {
    const params = {
      limit: LIMIT,
      ...qs.parse(location.search),
    };
    const { data } = await restApi.get(`/collections`, { params });
    setData(data);
  }, [location.search]);
  useEffect(() => {
    loadData().catch(console.warn);
  }, [loadData]);

  const columns = [
    {
      title: '이미지',
      dataIndex: 'image',
      key: 'image',
      width: 80,
      align: 'center',
      render: (id) => <Image style={{ width: 50, height: 50 }} src={getAPIHost() + '/files/' + id} />,
    },
    {
      title: '종목',
      dataIndex: 'main_positions',
      key: 'main_positions',
      width: 180,
      render: (v) => v?.map((o) => POSITION_LABELS[o])?.join(','),
    },
    {
      title: '제목',
      dataIndex: 'title',
      key: 'title',
      render: (data, row) => {
        return (
          <>
            <b>{data}</b>
            <br />
            <small>{row.from}</small>
          </>
        );
      },
    },
    {
      title: '종류',
      dataIndex: 'master',
      key: 'master',
      width: 80,
      align: 'center',
      render: (v) => (v ? <b style={{ color: '#333' }}>추천</b> : <span style={{ color: '#999' }}>일반</span>),
    },
    {
      title: '견적',
      dataIndex: 'calc_enabled',
      key: 'calc_enabled',
      width: 80,
      align: 'center',
      render: (v) => (v ? <b style={{ color: '#333' }}>가능</b> : <span style={{ color: '#999' }}>불가</span>),
    },
    {
      title: '공개',
      dataIndex: 'active_all',
      key: 'active_all',
      width: 80,
      align: 'center',
      render: (v) => (v ? <b style={{ color: '#333' }}>전체</b> : <span style={{ color: '#999' }}>본인만</span>),
    },
    {
      title: '등록일',
      dataIndex: 'created_at',
      key: 'created_at',
      width: 160,
      align: 'center',
      render: (v) => moment(v).format('YYYY-MM-DD HH:mm'),
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'more',
      width: 120,
      align: 'center',
      render: (id) => {
        return (
          <Link to={`/collections/styles/${id}`}>
            <Button size={'small'}>자세히보기</Button>
          </Link>
        );
      },
    },
  ];

  const searchField = [
    {
      width: 0.5,
      label: '일반검색',
      type: 'input',
      key: 'keyword',
    },
  ];

  const [tableHeight, setTableHeight] = useState(document.body.clientHeight - 60 - 180);
  useEffect(() => {
    const fn = () => {
      setTableHeight(document.body.clientHeight - 60 - 180);
    };
    window.addEventListener('resize', fn);
    return () => {
      window.removeEventListener('resize', fn);
    };
  }, []);

  return (
    <div>
      <PageHeader className="site-page-header" title="스타일관리" subTitle="등록된 스타일을 관리합니다." />
      <SearchForm
        fields={searchField}
        extra={{ page }}
        rightDeco={
          <Link to={'/collections/styles/create'}>
            <Button>등록</Button>
          </Link>
        }
      />
      <Table
        dataSource={data?.items ?? []}
        columns={columns}
        scroll={{ y: tableHeight }}
        pagination={{
          current: page + 1,
          total: data?.total_elements,
          pageSize: LIMIT,
        }}
        onChange={(e) => {
          setPage(e.current - 1);
        }}
      />
    </div>
  );
};

export default CollectionsItemsIndex;
