import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Card, Col, Form, Input, Modal, PageHeader, Row, Tree } from 'antd';
import { restApi } from '../../../apis';

const renderChild = (items, depth, handleCreateSub, handleDelete) => {
  return items?.map((item) => {
    const args = {
      ...item,
      title: (
        <div style={{ display: 'flex', flex: 1, flexDirection: 'row', width: '100%' }}>
          <div style={{ flex: 1 }}>{`${item.label}`}</div>
          <Button.Group>
            {depth <= 2 && (
              <Button size={'small'} onClick={() => handleCreateSub(item)}>
                하위추가
              </Button>
            )}
            <Button type={'danger'} size={'small'} onClick={() => handleDelete(item.code)}>
              삭제
            </Button>
          </Button.Group>
        </div>
      ),
      key: item.code,
    };

    if (item.children) {
      args.children = renderChild(item.children, depth + 1, handleCreateSub, handleDelete);
    } else {
      args.isLeaf = true;
    }
    return args;
  });
};

const ProductsCategoriesIndex = (props) => {
  const [data, setData] = useState([]);
  const loadData = async () => {
    const { data } = await restApi.get(`/product_categories`);
    setData(data);
  };

  useEffect(() => {
    loadData().catch(console.warn);
  }, []);

  const form = useRef();
  const form2 = useRef();
  const [loading, setLoading] = useState(false);
  const [createForm, setCreateForm] = useState();
  const handleCreateSub = (parent) => {
    setCreateForm(parent);
  };

  const handleDelete = useCallback(async (id) => {
    if (!window.confirm('정말 삭제하시겠습니까?')) return;
    await restApi.delete(`/product_categories/${id}`);
    loadData().catch(console.warn);
  }, []);

  const handleSubmit = async (data) => {
    setLoading(true);
    await restApi.post(`/product_categories`, { parent: createForm?.code, ...data });
    await loadData();
    setLoading(false);
    setCreateForm(undefined);
  };

  const handleSubmit2 = async (data) => {
    setLoading(true);
    await restApi.put(`/product_categories/${editName?.code}`, { label: data.label, order_index: data.order_index });
    await loadData();
    setLoading(false);
    setEditName(undefined);
  };

  const treeData = useMemo(() => {
    return [
      {
        title: (
          <div style={{ display: 'flex', flex: 1, flexDirection: 'row', width: '100%' }}>
            <div style={{ flex: 1 }}>전체</div>
            <Button.Group>
              <Button size={'small'} onClick={() => handleCreateSub({})}>
                하위추가
              </Button>
              <Button type={'danger'} size={'small'} disabled>
                삭제
              </Button>
            </Button.Group>
          </div>
        ),
        key: '_all',
        isLeaf: false,
        children: renderChild(data, 0, handleCreateSub, handleDelete),
      },
    ];
  }, [data, handleDelete]);

  const [editName, setEditName] = useState();
  const onSelect = (selectedKeys, e) => {
    if (selectedKeys?.length) {
      setEditName({
        code: selectedKeys?.[0],
        label: e.node.label,
        order_index: e.node.order_index
      });
    }
  };

  return (
    <div>
      <Modal
        visible={!!editName}
        title={'카테고리 수정'}
        onOk={() => form2.current.submit()}
        onCancel={() => setEditName(undefined)}
        confirmLoading={loading}
        destroyOnClose
      >
        <Form ref={form2} onFinish={handleSubmit2}>
          <Form.Item
            name={'label'}
            label={'이름'}
            style={{ marginBottom: 15 }}
            rules={[{ required: true, message: '이름항목은 필수입니다' }]}
            initialValue={editName?.label}
          >
            <Input />
          </Form.Item>
          <Form.Item
              name={'order_index'}
              label={'순서값'}
              style={{ marginBottom: 15 }}
              initialValue={editName?.order_index}
          >
            <Input />
          </Form.Item>
          <div style={{ opacity: 0, position: 'absolute' }}>
            <Button htmlType={'submit'} />
          </div>
        </Form>
      </Modal>
      <Modal
        visible={createForm}
        title={'카테고리 추가'}
        onOk={() => form.current.submit()}
        onCancel={() => setCreateForm(undefined)}
        confirmLoading={loading}
        destroyOnClose
      >
        <Form ref={form} onFinish={handleSubmit}>
          {createForm?.code && (
            <Form.Item label={'상위 카테고리'} style={{ marginBottom: 15 }}>
              [{createForm?.code}] {createForm?.label}
            </Form.Item>
          )}
          <Form.Item
            name={'label'}
            label={'이름'}
            style={{ marginBottom: 15 }}
            rules={[{ required: true, message: '이름항목은 필수입니다' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name={'alias'} label={'별칭'}>
            <Input />
          </Form.Item>
          <div style={{ opacity: 0, position: 'absolute' }}>
            <Button htmlType={'submit'} />
          </div>
        </Form>
      </Modal>
      <PageHeader className="site-page-header" title="카테고리 관리" subTitle="상품 관리" />
      <div style={{ maxWidth: 1280 }}>
        <Row gutter={10}>
          <Col span={10}>
            <Card>
              <div style={{ height: 500 }}>
                <Tree
                  onSelect={onSelect}
                  treeData={treeData}
                  showLine
                  height={500}
                  virtual={true}
                  defaultExpandedKeys={['_all']}
                />
              </div>
            </Card>
          </Col>
          <Col span={14}>
            <Card style={{ minHeight: 550 }}></Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ProductsCategoriesIndex;
