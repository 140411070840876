import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import qs from 'query-string';

const ProductsItemsDetails = (props) => {
  const params = useParams();
  const { search } = useLocation();

  return (
    <div>
      <h1>등록상품 관리 상세페이지</h1>
      <div>{JSON.stringify(params, null, 2)}</div>
      <div>{JSON.stringify(qs.parse(search), null, 2)}</div>
    </div>
  );
};

export default ProductsItemsDetails;
