import React, { useState } from 'react';
import styles from './BasicLayout.module.less';
import AuthAside from '../components/auth/Aside';
import useRoutes from './useRoutes';
import { shallowEqual, useSelector } from 'react-redux';
import { Button, Col, Menu, Row, Spin } from 'antd';
import Login from '../components/auth/Login';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { BellOutlined, CloseOutlined, MenuOutlined, UserOutlined } from '@ant-design/icons';

const childRenderer = (page, parent) => {
  const args = {
    path: [parent, page.path].filter((v) => !!v).join('/'),
  };
  if (page.children?.length) {
    //, args.path
    args.path = args.path + '/*';
    args.children = page.children?.map((child) => childRenderer(child));
  }
  if (page.screen) {
    const Elem = page.screen;
    args.element = <Elem />;
  }
  return <Route key={page.path} {...args} />;
};

const BasicLayout = ({ children, loading }) => {
  const { principal } = useSelector((s) => s.auth, shallowEqual);
  const { routes, pages } = useRoutes();
  const [sideOpen, setSideOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <div
      className={`${styles.basic_layout} ${!principal || loading ? styles.auth : ''} ${
        sideOpen ? styles.side_open : ''
      }`}
    >
      <aside>
        <div className={styles.intro_bg} />
        <div className={styles.bi}>
          <img alt={'baba'} src={require('../assets/logo.png')} />
          {!!principal && (
            <Button
              onClick={() => setSideOpen((x) => !x)}
              type="text"
              icon={<CloseOutlined style={{ color: '#fff' }} />}
            />
          )}
        </div>
        {!principal ? (
          <div className={styles.center_contents}>
            <AuthAside />
          </div>
        ) : (
          <div className={styles.aside_body}>
            <nav className={styles.menu}>
              <Menu
                onSelect={console.log}
                onClick={(item) => navigate(item.key)}
                style={{ width: '100%' }}
                theme={'dark'}
                mode="inline"
              >
                {routes?.map((route) => {
                  const Icon = route.icon;
                  if (!route.children?.length) {
                    return (
                      <Menu.Item key={route.path} icon={<Icon />}>
                        {route.title}
                      </Menu.Item>
                    );
                  }
                  // <Menu.ItemGroup key="g1" title="Item 1">
                  //   <Menu.Item title={'Option 1'} key="1">
                  //     Option 1
                  //   </Menu.Item>
                  //   <Menu.Item key="2">Option 2</Menu.Item>
                  // </Menu.ItemGroup>

                  const items = route?.children ?? [];
                  return (
                    <Menu.SubMenu key={route.path} icon={<Icon />} title={route.title}>
                      {items.map((child) => (
                        <Menu.Item key={child.path}>{child.title}</Menu.Item>
                      ))}
                    </Menu.SubMenu>
                  );
                })}
              </Menu>
            </nav>
          </div>
        )}
      </aside>

      {!principal ? (
        <div className={styles.auth_body}>{!!loading ? <Spin size={'large'} /> : <Login />}</div>
      ) : (
        <div className={styles.body}>
          <header>
            <Row gutter={5}>
              <Col className={styles.menu_button}>
                <Button onClick={() => setSideOpen((x) => !x)} shape="circle" icon={<MenuOutlined />} />
              </Col>
              <Col flex={'1'} />

              <Col>
                <Button shape="round" icon={<UserOutlined />}>
                  {principal?.nickname}
                </Button>
              </Col>
            </Row>
          </header>
          <Routes>{pages?.filter((v) => !!v.screen)?.map((page) => childRenderer(page, undefined))}</Routes>
        </div>
      )}
    </div>
  );
};

export default BasicLayout;
