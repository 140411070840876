import React, { useMemo, useRef, useState } from 'react';
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Tag,
  TreeSelect,
} from 'antd';
import { CALC_KEYS, POSITION_LABELS, POSITIONS, PRINT_LABELS } from '../../common/define';
import useTreeData from '../../hooks/useTreeData';
import produce from 'immer';
import { shallowEqual, useSelector } from 'react-redux';

global.IF = (c, t, f) => {
  return c ? t : f;
};

global.SQRT = (v) => Math.sqrt(v);

const EstimateTplForm = ({ modify, setModify, loading, onSubmit, onDelete, mc_id, sc_id }) => {
  const { treeData } = useTreeData();

  const estimateTpls = useSelector((s) => s.common?.estimateTpls, shallowEqual);

  const [calcError, setCalcError] = useState();

  const form = useRef();
  const handleSubmit = async () => {
    const calc = modify?.calc_fields?.join(' ');
    try {
      eval(calc.replace(/\$\{[a-zA-Z0-9\_]+\}/g, '1'));
      onSubmit?.();
    } catch (e) {
      setCalcError(e?.message);
    }
  };

  const tagItems = useMemo(() => {
    const items = Object.values(CALC_KEYS);

    return items
      .filter((v) => {
        return v.allow?.filter((v2) => modify?.element_types?.includes(v2)).length > 0;
      })
      .map((v) => ({
        label: v.label,
        value: '${' + v.key + '}',
      }));
  }, [modify?.element_types]);

  const options = modify?.select_option || {};
  const setOptions = (a, b, c, d) => {
    setModify(
      produce(modify, (draft) => {
        if (!draft.select_option[a]) {
          draft.select_option[a] = [];
        }
        if (!draft.select_option[a][b]) {
          draft.select_option[a][b] = {};
        }
        draft.select_option[a][b][c] = d;
      }),
    );
  };

  const tplOptions = useMemo(() => {
    const o = [...(estimateTpls ?? [])]?.filter((v) => v.id !== modify?.id && v.selectable);
    o.sort((a, b) => {
      const a1 = (String(a.main_category.id) === mc_id ? 10 : 0) + (String(a.side_category.id) === sc_id ? 1 : 0);
      const b1 = (String(b.main_category.id) === mc_id ? 10 : 0) + (String(b.side_category.id) === sc_id ? 1 : 0);
      return a1 > b1 ? -1 : a1 < b1 ? 1 : 0;
    });

    return o?.map((v) => ({
      value: v.id,
      label: [v.main_category?.name, v.side_category?.name, v.name]?.filter((v) => !!v)?.join(' > '),
    }));
  }, [modify?.id, estimateTpls, mc_id, sc_id]);

  return (
    <Modal
      width={800}
      visible={!!modify}
      title={modify?.id ? '템플릿 수정' : '템플릿 등록'}
      onOk={handleSubmit}
      onCancel={() => {
        setModify(undefined);
      }}
      destroyOnClose
      maskClosable={false}
      confirmLoading={loading}
      closable={!loading}
      footer={[
        modify?.id && (
          <Button onClick={onDelete} danger>
            삭제
          </Button>
        ),
        <Button key="submit" type="primary" loading={loading} onClick={() => form.current.submit()}>
          저장
        </Button>,
        <Button
          key="back"
          onClick={() => {
            setModify(undefined);
          }}
        >
          취소
        </Button>,
      ]}
    >
      <Form onFinish={handleSubmit} ref={form}>
        <Form.Item label={'명칭'} style={{ marginBottom: 20 }}>
          <Input
            value={modify?.name}
            onChange={(e) => {
              const value = e.target.value;
              setModify((x) => ({
                ...x,
                name: value,
              }));
            }}
          />
        </Form.Item>
        <Form.Item label={'설명'} style={{ marginBottom: 20 }}>
          <Input
            value={modify?.description}
            onChange={(e) => {
              const value = e.target.value;
              setModify((x) => ({
                ...x,
                description: value,
              }));
            }}
          />
        </Form.Item>
        <Form.Item label={'사용 위치'} style={{ marginBottom: 20 }}>
          <Checkbox.Group
            value={modify?.element_types}
            onChange={(value) => {
              setModify((x) => ({
                ...x,
                element_types: value.includes('_all') ? ['_all'] : value,
              }));
            }}
          >
            <Row>
              {POSITIONS?.map((v) => (
                <Col span={12}>
                  <Checkbox value={v} disabled={v !== '_all' && modify?.element_types?.includes('_all')}>
                    {POSITION_LABELS[v]}
                  </Checkbox>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>
        </Form.Item>
        <Form.Item label={'선택가능여부'} style={{ marginBottom: 20 }}>
          <Radio.Group
            value={modify?.selectable}
            onChange={(e) => {
              const value = e.target.value;
              setModify((x) => ({
                ...x,
                selectable: value,
              }));
            }}
          >
            <Row>
              <Col span={12}>
                <Radio value={true}>선택가능</Radio>
              </Col>
              <Col span={12}>
                <Radio value={false}>선택불가</Radio>
              </Col>
            </Row>
          </Radio.Group>
        </Form.Item>
        {!modify?.selectable && (
          <>
            <Form.Item label={'부모값 선택'} style={{ marginBottom: 20 }}>
              <Select
                mode={'multiple'}
                options={tplOptions}
                value={modify?.parents}
                onChange={(value) => {
                  setModify((x) => ({ ...x, parents: value }));
                }}
              />
            </Form.Item>
            <Form.Item label={'추가조건 선택'} style={{ marginBottom: 20 }}>
              <Row gutter={10}>
                <Col span={16}>
                  <Select
                    mode={'multiple'}
                    options={tplOptions}
                    value={modify?.requireds}
                    onChange={(value) => {
                      setModify((x) => ({ ...x, requireds: value }));
                    }}
                  />
                </Col>
                <Col span={24 - 16}>
                  <Select
                    style={{ width: '100%' }}
                    value={!!modify?.required_having}
                    onChange={(value) => {
                      setModify((x) => ({ ...x, required_having: value }));
                    }}
                  >
                    <Select.Option value={true}>가 있을때</Select.Option>
                    <Select.Option value={false}>가 없을때</Select.Option>
                  </Select>
                </Col>
              </Row>
            </Form.Item>
          </>
        )}
        <Form.Item label={'제품선택여부'} style={{ marginBottom: 20 }}>
          <Radio.Group
            value={modify?.use_product}
            onChange={(e) => {
              const value = e.target.value;
              setModify((x) => ({
                ...x,
                use_product: value,
              }));
            }}
          >
            <Row>
              <Col span={12}>
                <Radio value={true}>선택가능</Radio>
              </Col>
              <Col span={12}>
                <Radio value={false}>선택불가</Radio>
              </Col>
            </Row>
          </Radio.Group>
        </Form.Item>
        {modify?.use_product ? (
          <>
            <Form.Item label={'제품카테고리'} style={{ marginBottom: 20 }}>
              <TreeSelect
                style={{ width: '100%' }}
                treeData={treeData}
                multiple
                value={modify?.use_code}
                onChange={(value) => {
                  setModify((x) => ({
                    ...x,
                    use_code: value,
                  }));
                }}
              />
            </Form.Item>

            <Form.Item label={'제품최대갯수'} style={{ marginBottom: 20 }}>
              <InputNumber
                value={modify?.use_product_size}
                style={{ width: '100%' }}
                onChange={(value) => {
                  setModify((x) => ({
                    ...x,
                    use_product_size: value,
                  }));
                }}
              />
            </Form.Item>

            <Form.Item label={'제품수량관리'} style={{ marginBottom: 20 }}>
              <Radio.Group
                value={modify?.use_product_multi}
                onChange={(e) => {
                  const value = e.target.value;
                  setModify((x) => ({
                    ...x,
                    use_product_multi: value,
                  }));
                }}
              >
                <Space>
                  <Radio value={true}>수량선택가능</Radio>
                  <Radio value={false}>단품전용</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </>
        ) : (
          <Form.Item label={'적용금액'} style={{ marginBottom: 20 }}>
            <InputNumber
              value={modify?.price}
              style={{ width: '100%' }}
              onChange={(value) => {
                setModify((x) => ({
                  ...x,
                  price: value,
                }));
              }}
            />
          </Form.Item>
        )}

        <Form.Item label={'방별 기본금액'} style={{ marginBottom: 20 }}>
          <InputNumber
            value={modify?.extra_price}
            style={{ width: '100%' }}
            onChange={(value) => {
              setModify((x) => ({
                ...x,
                extra_price: value,
              }));
            }}
          />
        </Form.Item>

        <Form.Item label={'전체 기본가격'} style={{ marginBottom: 20 }}>
          <InputNumber
            value={modify?.extra_add_price}
            style={{ width: '100%' }}
            onChange={(value) => {
              setModify((x) => ({
                ...x,
                extra_add_price: value,
              }));
            }}
          />
        </Form.Item>

        <Form.Item label={'출력명칭'} style={{ marginBottom: 20 }}>
          <Select
            value={modify?.print_mode}
            style={{ width: '100%' }}
            onChange={(value) => {
              setModify((x) => ({
                ...x,
                print_mode: value,
              }));
            }}
          >
            {Object.keys(PRINT_LABELS).map((key) => (
              <Select.Option value={key}>{PRINT_LABELS[key]}</Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          className={'calc_input'}
          label={'출력필드'}
          style={{ marginBottom: 20 }}
          validateStatus={calcError && 'error'}
          help={calcError}
        >
          <Select
            mode="tags"
            value={modify?.calc_fields}
            style={{ width: '100%' }}
            tagRender={({ label, value }) => {
              if (
                Object.keys(CALC_KEYS)
                  ?.map((v) => '${' + v + '}')
                  .includes(value)
              ) {
                return <Tag>{label}</Tag>;
              }
              return <>{value}</>;
            }}
            onChange={(value) => {
              setCalcError(undefined);
              setModify((x) => ({
                ...x,
                calc_fields: value,
              }));
            }}
            options={tagItems}
          />
        </Form.Item>

        <Form.Item label={'전산코드'} style={{ marginBottom: 20 }}>
          <Input
            value={modify?.proc_code}
            style={{ width: '100%' }}
            onChange={(e) => {
              setModify((x) => ({
                ...x,
                proc_code: e.target.value,
              }));
            }}
          />
          <span style={{ marginTop: 5, fontSize: 12, color: '#777' }}>
            벽체: wa / 바닥: fl / 천정: ce / 주방싱크대: kc / 화장실: cr / 현관: en / 발코니: bc / 창호: wd
          </span>
        </Form.Item>

        <Form.Item label={'표시그룹'} style={{ marginBottom: 20 }}>
          <Select
            value={modify?.group_code ?? ""}
            style={{ width: '100%' }}
            onChange={(value) => {
              setModify((x) => ({
                ...x,
                group_code: value,
              }));
            }}
          >
            <Select.Option value={""}>해당없음</Select.Option>
            <Select.Option value={"WA"}>벽체</Select.Option>
            <Select.Option value={"FL"}>바닥</Select.Option>
            <Select.Option value={"CE"}>천정</Select.Option>
            <Select.Option value={"KC"}>주방,싱크대</Select.Option>
            <Select.Option value={"CR"}>위생기구</Select.Option>
            <Select.Option value={"EN"}>현관</Select.Option>
            <Select.Option value={"BA"}>발코니</Select.Option>
            <Select.Option value={"EX"}>확장</Select.Option>
            <Select.Option value={"HF"}>홈퍼니싱</Select.Option>
            <Select.Option value={"WD"}>창호</Select.Option>
            <Select.Option value={"CC"}>서비스</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item label={'기본표출여부'} style={{ marginBottom: 20 }}>
          <Radio.Group
            value={modify?.place_holder}
            onChange={(e) => {
              const value = e.target.value;
              setModify((x) => ({
                ...x,
                place_holder: value,
              }));
            }}
          >
            <Space>
              <Radio value={true}>기본 표출</Radio>
              <Radio value={false}>상품 선택됬을 시 표출</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>


        {!!modify?.selectable && (
          <>
            <Divider plain>추가옵션</Divider>
            {/*<Form.Item*/}
            {/*  label={*/}
            {/*    <Space>*/}
            {/*      <span>포함옵션</span>*/}
            {/*      <Button size={'small'}>추가</Button>*/}
            {/*    </Space>*/}
            {/*  }*/}
            {/*  style={{ marginBottom: 20 }}*/}
            {/*>*/}
            {/*  {options?.includes?.map((inc) => (*/}
            {/*    <div style={{ marginBottom: 10 }}>*/}
            {/*      /!*<Input placeholder={'조건'} style={{ marginBottom: 5 }} />*!/*/}
            {/*      <Select mode={'multiple'} style={{ width: '100%' }} />*/}
            {/*    </div>*/}
            {/*  ))}*/}
            {/*</Form.Item>*/}

            <Form.Item
              label={
                <Space>
                  <span>제외옵션</span>
                  <Button
                    size={'small'}
                    onClick={() => {
                      setModify(
                        produce(modify, (draft) => {
                          const data = { ...options };
                          if (!data.excludes) data.excludes = [];
                          data.excludes.push({ confirm: undefined, ids: [] });
                          draft.select_option = data;
                        }),
                      );
                    }}
                  >
                    추가
                  </Button>
                </Space>
              }
              style={{ marginBottom: 20 }}
            >
              {options?.excludes?.map((exc, i) => (
                <div style={{ marginBottom: 10 }}>
                  <Input
                    value={exc.confirm}
                    onChange={(e) => {
                      const value = e.target.value;
                      setOptions('excludes', i, 'confirm', value);
                    }}
                    placeholder={'해제시 물어볼 멘트 (없는경우 즉시)'}
                    style={{ marginBottom: 5 }}
                  />
                  <Select
                    mode={'multiple'}
                    value={exc.ids}
                    style={{ width: '100%' }}
                    onChange={(value) => {
                      setOptions('excludes', i, 'ids', value);
                    }}
                    options={tplOptions}
                  />
                </div>
              ))}
            </Form.Item>
          </>
        )}
      </Form>
    </Modal>
  );
};

export default EstimateTplForm;
