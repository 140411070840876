import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Input, Select, Space, Table } from 'antd';
import React from 'react';
import { comma } from '../../../../common/utils';
import styles from './style.module.less';

export default function FloorConstruction({ children }) {
  const type_columns = [
    {
      title: '구분',
      width: 100,
      dataIndex: 'type',
      key: 'type',
      fixed: 'left',
      align: 'center',
    },
    {
      title: '명칭',
      width: 150,
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      align: 'center',
      render: () => {
        return (
          <>
            <Select
              defaultValue="바닥재 타일"
              options={
                [
                  // {
                  //   value: 'jack',
                  //   label: 'Jack',
                  // },
                  // {
                  //   value: 'lucy',
                  //   label: 'Lucy',
                  // },
                  // {
                  //   value: 'disabled',
                  //   disabled: true,
                  //   label: 'Disabled',
                  // },
                  // {
                  //   value: 'Yiminghe',
                  //   label: 'yiminghe',
                  // },
                ]
              }
            />
          </>
        );
      },
    },
    {
      title: '자재명',
      width: 250,
      dataIndex: 'material_name',
      key: 'material_name',
      fixed: 'left',
      align: 'center',
      render: () => {
        return (
          <>
            <span>A 66046</span>
            <Button type="primary" style={{ marginLeft: 80 }}>
              변경
            </Button>
          </>
        );
      },
    },
    {
      title: '소비자 견적',
      children: [
        {
          title: '계정구분',
          width: 100,
          dataIndex: 'account',
          key: 'account',
          fixed: 'left',
          align: 'center',
        },
        {
          title: '단위',
          width: 100,
          dataIndex: 'unit',
          key: 'unit',
          fixed: 'left',
          align: 'center',
        },
        {
          title: '수량',
          width: 100,
          dataIndex: 'quantity',
          key: 'quantity',
          fixed: 'left',
          align: 'center',
        },
        {
          title: '단가',
          width: 150,
          dataIndex: 'price',
          key: 'price',
          align: 'center',
          render: (value) => comma(value),
        },
        {
          title: '금액',
          width: 150,
          dataIndex: 'amount',
          key: 'amount',
          align: 'center',
          render: (value) => comma(value),
        },
      ],
    },
    {
      title: '제안견적',
      children: [
        {
          title: '계정구분',
          width: 100,
          dataIndex: 'sale_account',
          key: 'sale_account',
          fixed: 'left',
          align: 'center',
        },
        {
          title: '단위',
          width: 100,
          dataIndex: 'sale_unit',
          key: 'sale_unit',
          fixed: 'left',
          align: 'center',
        },
        {
          title: '수량',
          width: 100,
          dataIndex: 'sale_quantity',
          key: 'sale_quantity',
          fixed: 'left',
          align: 'center',
        },
        {
          title: '단가',
          width: 150,
          dataIndex: 'sale_price',
          key: 'sale_price',
          align: 'center',
          render: (value) => comma(value),
        },
        {
          title: '금액',
          width: 150,
          dataIndex: 'sale_amount',
          key: 'sale_amount',
          align: 'center',
          render: (value) => comma(value),
        },
      ],
    },
    {
      title: '차액',
      width: 150,
      dataIndex: 'diff',
      key: 'diff',
      align: 'center',
      render: (value) => comma(value),
    },
    // {
    //   title: '소비자 제안 금액',
    //   children: [
    //     {
    //       title: '금액',
    //       width: 150,
    //       dataIndex: 'deal_amount',
    //       key: 'deal_amount',
    //       align: 'center',
    //       render: () => <Input />,
    //     },
    //   ],
    // },
    {
      title: '',
      width: 150,
      dataIndex: 'button',
      key: 'button',
      align: 'center',
      render: () => (
        <>
          <Space>
            <Button icon={<PlusOutlined />} />
            <Button icon={<MinusOutlined />} />
          </Space>
        </>
      ),
    },
  ];

  const ESTIMATE_TYPE_DETAIL_DATA = [
    {
      type: '페인트',
      name: '벽체페인트',
      material_name: undefined,
      unit: 'PY',
      quantity: '1.2',
      account: '자재비',
      price: 100000,
      amount: 120000,
      sale_account: '자재비',
      sale_unit: 'M2',
      sale_quantity: '19.74',
      sale_price: 195000,
      sale_amount: 384996,
      diff: 0,
    },
  ];
  return (
    <div>
      <div className={styles.table}>
        {children}
        <Table columns={type_columns} dataSource={ESTIMATE_TYPE_DETAIL_DATA} pagination={false} />
      </div>
    </div>
  );
}
