import React, { useEffect, useMemo, useState } from 'react';
import { Button, Card, Form, Input, PageHeader, Switch, Table, Tabs } from 'antd';
import { restApi } from '../../../apis';
import { useParams } from 'react-router-dom';

const render = (v) => v?.toFixed(1);

const SettingsApartmentsDetails = (props) => {
  const params = useParams();

  const [data, setData] = useState({});
  const [sizes, setSizes] = useState([]);
  useEffect(() => {
    const loadData = async () => {
      const { data } = await restApi.get(`/apartments/${params?.id}`);
      setData({ ...data });

      const { data: items } = await restApi.get(`/apartments/${params?.id}/sizes`);
      setSizes(items);
    };
    loadData().catch(console.warn);
  }, [params?.id]);

  const tabs = useMemo(
    () =>
      sizes?.map((v) => {
        const columns = [
          {
            key: 'type',
            dataIndex: 'type',
            title: '구분',
            width: 200,
          },
          {
            key: 'size',
            dataIndex: 'size',
            title: '사이즈',
            align: 'center',
            width: 70,
          },
          {
            key: 'sash',
            dataIndex: 'sash',
            title: '하이샤시',
            width: 80,
            align: 'center',
            render,
          },
        ];

        const dataSource = [
          {
            type: '샷시면적',
            size: '평(자)',
            sash: v.sash,
          },
          {
            type: '바닥면적',
            size: '평',
          },
          {
            type: '벽체면적',
            size: '평',
          },
          {
            type: '화장실 바닥면적',
            size: 'm2',
          },
          {
            type: '화장실 벽체면적',
            size: 'm2',
          },
          {
            type: '붙박이장',
            size: '자',
          },
          {
            type: '신발장',
            size: '자',
          },
          {
            type: '몰딩',
            size: 'M',
          },
          {
            type: '문',
            size: 'EA',
          },
          {
            type: '발코니도어',
            size: 'EA',
          },
          {
            type: '중문',
            size: 'EA',
          },
          {
            type: '발코니',
            size: 'm2',
          },
          {
            type: '발코니장',
            size: '자',
          },
          {
            type: 'ㅡ자싱크대',
            size: 'M',
          },
          {
            type: 'ㅡ자싱크대앞 벽타일',
            size: 'm2',
          },
          {
            type: 'ㄱ자싱크대',
            size: 'M',
          },
          {
            type: 'ㄱ자싱크대앞 벽타일',
            size: 'm2',
          },
          {
            type: 'ㄷ자싱크대',
            size: 'M',
          },
          {
            type: 'ㄷ자싱크대 벽타일',
            size: 'm2',
          },
          {
            type: '11자싱크대',
            size: 'M',
          },
          {
            type: '11자싱크대앞 벽타일',
            size: 'm2',
          },
          {
            type: '화장실천정재',
            size: 'EA',
          },
        ];

        for (let i = 0; i < Math.max(v.living_rooms?.length ?? 0, 1); i++) {
          const item = v.living_rooms?.[i] ?? {};
          const key = 'living_rooms' + i;
          let title = '거실';
          if (v.living_rooms?.length > 1) {
            title += ` ${i + 1}`;
          }
          columns.push({
            key,
            dataIndex: key,
            title,
            width: 80,
            align: 'center',
            render,
          });
          dataSource[1][key] = item.foot_space;
          dataSource[2][key] = item.wall_space;
          dataSource[5][key] = item.closet;
          dataSource[7][key] = item.moulding;
          dataSource[9][key] = item.balcony_door;
          dataSource[11][key] = item.balcony;
          dataSource[12][key] = item.balcony_storage;
        }

        for (let i = 0; i < Math.max(v.kitchens?.length ?? 1, 1); i++) {
          const item = v.kitchens?.[i] ?? {};
          const key = 'kitchens' + i;
          let title = '주방';
          if (v.kitchens?.length > 1) {
            title += ` ${i + 1}`;
          }
          columns.push({
            key,
            dataIndex: key,
            title,
            width: 80,
            align: 'center',
            render,
          });
          dataSource[1][key] = item.foot_space;
          dataSource[2][key] = item.wall_space;
          dataSource[7][key] = item.moulding;
          dataSource[9][key] = item.balcony_door;
          dataSource[11][key] = item.balcony;
          dataSource[12][key] = item.balcony_storage;
          dataSource[13][key] = item.sinkType1;
          dataSource[14][key] = item.walltileType1;
          dataSource[15][key] = item.sinkType2;
          dataSource[16][key] = item.walltileType2;
          dataSource[17][key] = item.sinkType3;
          dataSource[18][key] = item.walltileType3;
          dataSource[19][key] = item.sinkType4;
          dataSource[20][key] = item.walltileType4;
        }

        let room_count = v.room_count;

        for (let i = 0; i < Math.max(v.master_rooms?.length ?? 1, 1); i++) {
          const item = v.master_rooms?.[i] ?? {};
          const key = 'master_rooms' + i;
          let title = '안방';
          if (v.master_rooms?.length > 1) {
            title += ` ${i + 1}`;
          }
          columns.push({
            key,
            dataIndex: key,
            title,
            width: 80,
            align: 'center',
            render,
          });
          dataSource[1][key] = item.foot_space;
          dataSource[2][key] = item.wall_space;
          dataSource[5][key] = item.closet;
          dataSource[7][key] = item.moulding;
          dataSource[8][key] = item.door;
          dataSource[11][key] = item.balcony;
          dataSource[12][key] = item.balcony_storage;
          room_count--;
        }

        for (let i = 0; i < Math.max(v.rooms?.length ?? 0, room_count); i++) {
          const item = v.rooms?.[i] ?? {};
          const key = 'rooms' + i;
          let title = '방';
          if (Math.max(v.rooms?.length ?? 0, room_count) > 1) {
            title += ` ${i + 1}`;
          }
          columns.push({
            key,
            dataIndex: key,
            title,
            width: 80,
            align: 'center',
            render,
          });
          dataSource[1][key] = item.foot_space;
          dataSource[2][key] = item.wall_space;
          dataSource[5][key] = item.closet;
          dataSource[7][key] = item.moulding;
          dataSource[8][key] = item.door;
          dataSource[11][key] = item.balcony;
          dataSource[12][key] = item.balcony_storage;
        }

        for (let i = 0; i < Math.max(v.entrance?.length ?? 1, 1); i++) {
          const item = v.entrance?.[i] ?? {};
          const key = 'entrance' + i;
          let title = '현관출입부';
          if (v.entrance?.length > 1) {
            title += ` ${i + 1}`;
          }
          columns.push({
            key,
            dataIndex: key,
            title,
            width: 80,
            align: 'center',
            render,
          });
          dataSource[1][key] = item.foot_space;
          dataSource[2][key] = item.wall_space;
          dataSource[6][key] = item.shoe_rack;
          dataSource[7][key] = item.moulding;
          dataSource[8][key] = item.door;
          dataSource[10][key] = item.inner_door;
        }

        let bath_count = v.bathroom_count;

        for (let i = 0; i < Math.max(v.living_room_cr?.length ?? 1, 1); i++) {
          const item = v.living_room_cr?.[i] ?? {};
          const key = 'living_room_cr' + i;
          let title = '거실화장실';
          if (v.living_room_cr?.length > 1) {
            title += ` ${i + 1}`;
          }
          columns.push({
            key,
            dataIndex: key,
            title,
            width: 90,
            align: 'center',
            render,
          });
          dataSource[3][key] = item.cr_foot_space;
          dataSource[4][key] = item.cr_wall_space;
          dataSource[8][key] = item.door;
          dataSource[21][key] = item.celling_board;
          bath_count--;
        }

        for (let i = 0; i < Math.max(v.master_room_cr?.length ?? 0, bath_count); i++) {
          const item = v.master_room_cr?.[i] ?? {};
          const key = 'master_room_cr' + i;
          let title = '안방화장실';
          if (v.master_room_cr?.length > 1) {
            title += ` ${i + 1}`;
          }
          columns.push({
            key,
            dataIndex: key,
            title,
            width: 90,
            align: 'center',
            render,
          });
          dataSource[3][key] = item.cr_foot_space;
          dataSource[4][key] = item.cr_wall_space;
          dataSource[8][key] = item.door;
          dataSource[21][key] = item.celling_board;
          bath_count--;
        }

        for (let i = 0; i < Math.max(v.room_cr?.length ?? 0, bath_count); i++) {
          const item = v.room_cr?.[i] ?? {};
          const key = 'room_cr' + i;
          let title = '각방화장실';
          if (v.room_cr?.length > 1) {
            title += ` ${i + 1}`;
          }
          columns.push({
            key,
            dataIndex: key,
            title,
            width: 90,
            align: 'center',
            render,
          });
          dataSource[3][key] = item.cr_foot_space;
          dataSource[4][key] = item.cr_wall_space;
          dataSource[8][key] = item.door;
          dataSource[21][key] = item.celling_board;
          bath_count--;
        }

        return {
          id: v.id,
          title: v.name + '㎡',
          columns,
          dataSource,
        };
      }),
    [sizes],
  );

  const handleSubmit = async () => {};

  return (
    <div>
      <PageHeader className="site-page-header" title="아파트 정보" subTitle={'설정'} />
      <Form layout={'horizontal'} onFinish={handleSubmit}>
        <div style={{ maxWidth: 1200 }}>
          <Card>
            <Form.Item label={'제목'} style={{ marginBottom: 20 }}>
              <Input value={data?.name} onChange={(e) => setData((x) => ({ ...x, name: e.target.value }))} />
            </Form.Item>
            <Form.Item label={'지번주소'} style={{ marginBottom: 20 }}>
              <Input value={data?.address} onChange={(e) => setData((x) => ({ ...x, address: e.target.value }))} />
            </Form.Item>
            <Form.Item label={'도로명주소'} style={{ marginBottom: 20 }}>
              <Input
                value={data?.road_address}
                onChange={(e) => setData((x) => ({ ...x, road_address: e.target.value }))}
              />
            </Form.Item>
            <Form.Item label={'허가일자'} style={{ marginBottom: 20 }}>
              <Input value={data?.used_at} onChange={(e) => setData((x) => ({ ...x, used_at: e.target.value }))} />
            </Form.Item>
            <Form.Item label={'관리소 연락처'} style={{ marginBottom: 20 }}>
              <Input
                value={data?.manager_phone}
                onChange={(e) => setData((x) => ({ ...x, manager_phone: e.target.value }))}
              />
            </Form.Item>
            <Form.Item label={'검색가능여부'} style={{ marginBottom: 20 }}>
              <Switch
                checked={data?.active}
                onClick={() => {
                  setData((x) => ({ ...x, active: !x.active }));
                }}
              />
            </Form.Item>
          </Card>
          <div style={{ textAlign: 'right', marginTop: 10 }}>
            <Button htmlType={'submit'} type={'primary'} size={'large'}>
              저장
            </Button>
          </div>
        </div>

        <Card style={{ marginTop: 20 }}>
          <Tabs>
            {tabs?.map((tab) => {
              return (
                <Tabs.TabPane key={`${tab.id}`} tab={tab.title}>
                  <Table
                    scroll={{ x: tab.columns?.reduce((a, c) => a + c.width, 0) }}
                    dataSource={tab.dataSource}
                    columns={tab.columns}
                    pagination={false}
                  />
                </Tabs.TabPane>
              );
            })}
          </Tabs>
        </Card>
      </Form>
    </div>
  );
};

export default SettingsApartmentsDetails;
