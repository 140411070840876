import { useMemo } from 'react';
import {
  BookOutlined,
  DeploymentUnitOutlined,
  FileDoneOutlined,
  HomeOutlined,
  SettingOutlined,
  SnippetsOutlined,
  UserOutlined,
} from '@ant-design/icons';
import HomeIndex from '../pages';
import AccountUsersIndex from '../pages/accounts/users';
import AccountUsersDetails from '../pages/accounts/users/[id]';
import AccountAgenciesIndex from '../pages/accounts/agencies';
import AccountMakersIndex from '../pages/accounts/makers';
import ProductsCategoriesIndex from '../pages/products/categories';
import ContentNoticeIndex from '../pages/contents/notice';
import ContentNoticeCreate from '../pages/contents/notice/create';
import CollectionsItemsIndex from '../pages/collections/items';
import CollectionsItemsDetails from '../pages/collections/items/[id]';
import ProductsItemsIndex from '../pages/products/items';
import ProductsItemsDetails from '../pages/products/items/[id]';
import SettingsApartmentsIndex from '../pages/settings/apartments';
import SettingsApartmentsDetails from '../pages/settings/apartments/[id]';
import AccountsMakersDetails from '../pages/accounts/makers/[id]';
import QuotationUsersIndex from '../pages/quotation/users';
import QuotationUsersDetails from '../pages/quotation/users/[id]';
import QuotationApplicationIndex from '../pages/quotation/application';
import QuotationApplicationDetails from '../pages/quotation/application/[id]';
import QuotationListIndex from '../pages/quotation/list';
import QuotationListDetails from '../pages/quotation/list/[id]';
import SettingsEstimateTplIndex from '../pages/settings/estimate_tpls';
import SettingsEstimateCategoriesIndex from '../pages/settings/estimate_categories';
import SettingsEstimateCategoriesDetails from '../pages/settings/estimate_categories/[id]';
import CollectionsMastersIndex from '../pages/collections/masters';
import CollectionsMastersDetails from '../pages/collections/masters/[id]';
import ContentBannerIndex from '../pages/contents/banner';
import ContentBannerCreate from '../pages/contents/banner/create';
import ContentSectionIndex from '../pages/contents/section';
import ContentSectionForm from '../pages/contents/section/create';
import ContentPopupIndex from '../pages/contents/popup';
import ContentPopupCreate from '../pages/contents/popup/create';
import ContentFaqIndex from '../pages/contents/faq';
import CollectionsMagazinesIndex from '../pages/collections/magazines';
import CollectionsMagazinesDetails from '../pages/collections/magazines/[id]';
import CollectionsGroupsIndex from '../pages/collections/groups';
import CollectionsGroupDetails from '../pages/collections/groups/[id]';
import ContentSubBannerIndex from '../pages/contents/subbanner';
import ContentSubBannerCreate from '../pages/contents/subbanner/create';
import ContentRepBannerIndex from '../pages/contents/repbanner';
import ContentRepBannerCreate from '../pages/contents/repbanner/create';

export const pages = [
  {
    path: '/',
    title: '홈',
    icon: HomeOutlined,
    screen: HomeIndex,
    isMenu: true,
  },
  {
    path: '/accounts',
    title: '고객 관리',
    icon: UserOutlined,
    isMenu: true,
  },
  {
    path: '/accounts/users',
    title: '고객회원',
    isMenu: true,
    screen: AccountUsersIndex,
  },
  {
    path: '/accounts/users/:id',
    title: '고객회원',
    // isMenu: true,
    screen: AccountUsersDetails,
  },
  {
    path: '/accounts/agencies',
    title: '시공사',
    isMenu: true,
    screen: AccountAgenciesIndex,
  },
  {
    path: '/accounts/makers',
    title: '유통/제조사',
    isMenu: true,
    screen: AccountMakersIndex,
  },
  {
    path: '/accounts/makers/:id',
    title: '제품제공사 상세',
    screen: AccountsMakersDetails,
  },
  {
    path: '/quotation',
    title: '견적 관리',
    icon: FileDoneOutlined,
    isMenu: true,
  },

  {
    path: '/quotation/users',
    title: '등록 견적서',
    screen: QuotationUsersIndex,
    isMenu: true,
  },
  {
    path: '/quotation/users/:id',
    title: '등록 견적서',
    screen: QuotationUsersDetails,
  },

  {
    path: '/quotation/application',
    title: '공사 도움받기 신청',
    screen: QuotationApplicationIndex,
    isMenu: true,
  },
  {
    path: '/quotation/application/:id',
    title: '공사 도움받기 신청',
    screen: QuotationApplicationDetails,
  },
  {
    path: '/quotation/list',
    title: '공사 도움받기 현황',
    screen: QuotationListIndex,
    isMenu: true,
  },
  {
    path: '/quotation/list/:id',
    title: '공사 도움받기 현황',
    screen: QuotationListDetails,
    // isMenu: true,
  },

  {
    path: '/products',
    title: '상품 관리',
    icon: DeploymentUnitOutlined,
    isMenu: true,
  },
  {
    path: '/products/items',
    title: '등록상품 관리',
    screen: ProductsItemsIndex,
    isMenu: true,
  },
  {
    path: '/products/items/:id',
    title: '제품 관리 상세',
    screen: ProductsItemsDetails,
  },
  {
    path: '/products/categories',
    title: '카테고리 관리',
    screen: ProductsCategoriesIndex,
    isMenu: true,
  },
  {
    path: '/collections',
    title: '스타일 관리',
    icon: BookOutlined,
    isMenu: true,
    screen: CollectionsItemsIndex,
  },
  {
    path: '/collections/group',
    title: '전시관리',
    isMenu: true,
    screen: CollectionsGroupsIndex,
  },
  {
    path: '/collections/group/:id',
    title: '전시관리 상세',
    screen: CollectionsGroupDetails,
  },
  {
    path: '/collections/magazines',
    title: '스타일',
    isMenu: true,
    screen: CollectionsMagazinesIndex,
  },
  {
    path: '/collections/magazines/:id',
    title: '스타일 상세',
    screen: CollectionsMagazinesDetails,
  },
  {
    path: '/docs',
    title: '공사 컨텐츠 관리',
    icon: SnippetsOutlined,
    isMenu: true,
  },

  {
    path: '/docs/estimates/category',
    title: '공종 카테고리',
    screen: SettingsEstimateCategoriesIndex,
    isMenu: true,
  },
  {
    path: '/docs/estimates/category/:id',
    title: '공종 카테고리 상세',
    screen: SettingsEstimateCategoriesDetails,
  },

  //
  {
    path: '/docs/estimates/tpl',
    title: '견적 기본템플릿',
    screen: SettingsEstimateTplIndex,
    isMenu: true,
  },

  //
  {
    path: '/docs/guide',
    title: '공사 가이드',
    screen: SettingsEstimateTplIndex,
    isMenu: true,
  },

  {
    path: '/contents',
    title: '앱 컨텐츠 관리',
    icon: SnippetsOutlined,
    isMenu: true,
  },

  {
    path: '/contents/banners',
    title: '메인 배너',
    screen: ContentBannerIndex,
    isMenu: true,
  },
  {
    path: '/contents/banners/:id',
    screen: ContentBannerCreate,
  },

  {
    path: '/contents/subbanners',
    title: '서브 배너',
    screen: ContentSubBannerIndex,
    isMenu: true,
  },
  {
    path: '/contents/subbanners/:id',
    screen: ContentSubBannerCreate,
  },

  {
    path: '/contents/repbanners',
    title: '견적광고',
    screen: ContentRepBannerIndex,
    isMenu: true,
  },
  {
    path: '/contents/repbanners/:id',
    screen: ContentRepBannerCreate,
  },

  {
    path: '/contents/popups',
    title: '메인 팝업',
    screen: ContentPopupIndex,
    isMenu: true,
  },
  {
    path: '/contents/popups/:id',
    screen: ContentPopupCreate,
  },

  {
    path: '/contents/sections',
    title: '메인 섹션',
    screen: ContentSectionIndex,
    isMenu: true,
  },
  {
    path: '/contents/sections/:id',
    screen: ContentSectionForm,
  },

  {
    path: '/contents/notices',
    title: '공지사항',
    screen: ContentNoticeIndex,
    isMenu: true,
  },
  {
    path: '/contents/notices/create',
    title: '공지사항 등록',
    screen: ContentNoticeCreate,
  },

  {
    path: '/contents/faqs',
    title: 'FAQ',
    screen: ContentFaqIndex,
    isMenu: true,
  },
  {
    path: '/contents/faqs/create',
    title: 'FAQ 등록',
    screen: ContentNoticeCreate,
  },
  {
    path: '/settings',
    title: '설정',
    icon: SettingOutlined,
    isMenu: true,
  },
  {
    path: '/settings/banners',
    title: '관리자',
    screen: ProductsItemsIndex,
    isMenu: true,
  },
  {
    path: '/settings/apartments',
    title: '아파트 정보',
    screen: SettingsApartmentsIndex,
    isMenu: true,
  },
  {
    path: '/settings/apartments/:id',
    screen: SettingsApartmentsDetails,
  },
];

export const useRoutes = () => {
  const routes = useMemo(
    () =>
      pages
        ?.filter((v) => !!v.isMenu)
        ?.reduce((output, page) => {
          const path = page.path.substring(1);
          const parentPath = '/' + path?.substring(0, path.indexOf('/'));
          const parentIndex = path?.indexOf('/') >= 0 ? output.findIndex((v) => v.path === parentPath) : undefined;
          console.log({ path, parentPath, parentIndex });
          if (parentIndex === undefined) {
            output.push({
              path: page.path,
              title: page.title,
              icon: page.icon,
            });
          } else {
            if (!output[parentIndex].children) output[parentIndex].children = [];
            output[parentIndex].children.push({
              path: page.path,
              title: page.title,
            });
          }
          return output;
        }, []),
    [],
  );

  return { routes, pages };
};

export default useRoutes;
