import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import React, { useState } from 'react';
import { Button, Descriptions, Input, PageHeader, Select } from 'antd';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import { restApi } from '../../../apis';

const ContentNoticeCreate = () => {
  const [data, setData] = useState({
    title: '',
    contents: '',
    active: true,
    role_type: '',
  });

  const handleCreatePost = async () => {
    await restApi.post(`/posts`, {
      ...data,
      type: 'NOTICE',
    });
  };

  return (
    <div>
      <PageHeader className="site-page-header" title="공지사항 작성" />
      <Descriptions bordered>
        <Descriptions.Item label={'공지대상'} span={4} className="nopadding" style={{ padding: '10px 20px' }}>
          <Select
            value={data.role_type}
            onChange={(role_type) => setData((x) => ({ ...x, role_type }))}
            style={{ width: 140, fontSize: 14, marginRight: 10 }}
            size={'large'}
          >
            <Select.Option value="U">고객</Select.Option>
            <Select.Option value="W">시공사</Select.Option>
            <Select.Option value="P">제품제공자</Select.Option>
          </Select>
        </Descriptions.Item>
        <Descriptions.Item label={'제목'} span={4} className="nopadding">
          <Input
            value={data.title}
            onChange={(e) => {
              const title = e.target.value;
              setData((x) => ({ ...x, title }));
            }}
            bordered={false}
            placeholder="제목을 입력해주세요"
            style={{ padding: '10px 20px' }}
          />
        </Descriptions.Item>

        <Descriptions.Item label={'공지내용'} span={4} className="nopadding" style={{ verticalAlign: 'top' }}>
          <Editor
            toolbarClassName="toolbar-class"
            onChange={(v) => setData((x) => ({ ...x, contents: draftToHtml(v) }))}
            editorStyle={{ height: 350 }}
            toolbar={{
              options: [
                'inline',
                'blockType',
                'fontSize',
                'fontFamily',
                'list',
                'textAlign',
                'colorPicker',
                'link',
                'emoji',
                'remove',
                'history',
              ],
            }}
            // toolbarHidden
            placeholder="내용을 작성해주세요."
            // 한국어 설정
            localization={{
              locale: 'ko',
            }}
          />
        </Descriptions.Item>
      </Descriptions>
      <div style={{ justifyContent: 'flex-end' }}>
        {/*<Button*/}
        {/*  onClick={handleCancel}*/}
        {/*  size={'large'}*/}
        {/*  className={styles.basicButton}*/}
        {/*  style={{ marginRight: 5, height: 40, width: 100 }}*/}
        {/*>*/}
        {/*  취소*/}
        {/*</Button>*/}
        <Button onClick={handleCreatePost} size={'large'}>
          등록
        </Button>
      </div>
    </div>
  );
};

export default ContentNoticeCreate;
