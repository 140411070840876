import React, { useState } from 'react';
import { message, Upload } from 'antd';
import { getAPIHost } from '../apis';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}

const SingleImageUpload = ({ id, onChange }) => {
  const [loading, setLoading] = useState(false);
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      onChange?.(info.file.response?.id);
    }
  };

  const imageUrl = !id ? undefined : getAPIHost() + '/files/' + id;
  return (
    <Upload
      name="filedata"
      listType="picture-card"
      className="avatar-uploader"
      showUploadList={false}
      action={getAPIHost() + '/files'}
      method={'PUT'}
      beforeUpload={beforeUpload}
      onChange={handleChange}
    >
      {imageUrl ? <img src={imageUrl} alt={'file'} style={{ width: '100%' }} /> : uploadButton}
    </Upload>
  );
};

export default SingleImageUpload;
