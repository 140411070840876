import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Col, Form, Image, Input, message, PageHeader, Row, Select, Space, Table } from 'antd';
import { getAPIHost, restApi } from '../../../apis';
import { parseImageUri } from '../../../common/utils';
import { POSITION_LABELS } from '../../../common/define';
import moment from 'moment/moment';

const CollectionsGroupDetails = () => {
  const params = useParams();
  const isCreate = params?.id === 'create';

  const [data, setData] = useState({
    title: '',
    desc: '',
    tags: [],
  });

  const [items, setItems] = useState([]);

  useEffect(() => {
    if (!isCreate) {
      const loadData = async () => {
        const { data } = await restApi.get(`/magazines/groups/${params?.id}`);
        setData(data);
      };
      loadData().catch(console.warn);
    }
  }, [isCreate, params?.id]);

  useEffect(() => {
    const loadData = async () => {
      const { data: resp } = await restApi.get(`/magazines`, {
        params: {
          tags: data?.tags ?? [],
        },
      });
      setItems(resp);
    };
    loadData().catch(console.warn);
  }, [data.tags]);

  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      if (isCreate) {
        await restApi.post(`/magazines/groups`, data);
        message.success('저장되었습니다.');
        window.history.back();
      } else {
        console.log('!!', data);
        await restApi.put(`/magazines/groups/${params?.id}`, data);
        message.success('저장되었습니다.');
        window.history.back();
      }
    } catch (e) {
      console.warn(e);
    } finally {
      setLoading(false);
    }
  };

  const handleRemove = async () => {
    if (!window.confirm('삭제하시겠습니까?')) return;
    setLoading(true);
    try {
      await restApi.delete(`/magazines/groups/${params.id}`);
      message.success('삭제되었습니다.');
      window.history.back();
    } catch (e) {
      console.warn(e);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: '이미지',
      dataIndex: 'image',
      key: 'image',
      width: 80,
      align: 'center',
      render: (id) => <Image style={{ width: 50, height: 50 }} src={getAPIHost() + '/files/' + id} />,
    },
    {
      title: '종목',
      dataIndex: 'positions',
      key: 'positions',
      width: 180,
      render: (v) => v?.map((o) => POSITION_LABELS[o])?.join(','),
    },
    {
      title: '제목',
      dataIndex: 'title',
      key: 'title',
      render: (data, row) => {
        return (
          <>
            <b>{data}</b>
            <br />
            <small>{row.from}</small>
          </>
        );
      },
    },
  ];

  const [tableHeight, setTableHeight] = useState(document.body.clientHeight - 60 - 180);
  useEffect(() => {
    const fn = () => {
      setTableHeight(document.body.clientHeight - 60 - 180);
    };
    window.addEventListener('resize', fn);
    return () => {
      window.removeEventListener('resize', fn);
    };
  }, []);

  return (
    <div
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        marginLeft: -20,
        marginRight: -20,
        overflow: 'hidden',
      }}
    >
      <div style={{ height: 48, overflow: 'hidden', paddingLeft: 20, paddingRight: 20 }}>
        <PageHeader className="site-page-header" title="전시관리" subTitle="등록된 스타일 그룹을 관리합니다." />
      </div>
      <div
        style={{
          paddingLeft: 20,
          paddingRight: 20,
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row', height: 70, alignItems: 'center' }}>
          <div style={{ flex: 1 }}>{!isCreate && <span>URL :: /magazine_groups?id={params.id}</span>}</div>
          <div>
            <Space>
              <Button onClick={handleSubmit} loading={loading} type={'primary'}>
                저장
              </Button>
              {!isCreate && (
                <Button onClick={handleRemove} type={'danger'}>
                  삭제
                </Button>
              )}
            </Space>
          </div>
        </div>

        <div style={{ flex: 1, overflow: 'hidden' }}>
          <Row gutter={20} style={{ height: '100%' }}>
            <Col xs={24} md={12} span={12}>
              <Form layout={'horizontal'}>
                <Row gutter={20}>
                  <Col span={24}>
                    <Form.Item label={'제목'} style={{ marginBottom: 20 }}>
                      <Input
                        value={data.title}
                        onChange={(e) => {
                          const v = e.target.value;
                          setData((x) => ({
                            ...x,
                            title: v,
                          }));
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label={'설명'} style={{ marginBottom: 20 }}>
                      <Input.TextArea
                        style={{ height: 100 }}
                        value={data.desc}
                        onChange={(e) => {
                          const v = e.target.value;
                          setData((x) => ({
                            ...x,
                            desc: v,
                          }));
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label={'태그'} style={{ marginBottom: 20 }}>
                      <Select
                        value={data?.tags}
                        onChange={(value) => setData((x) => ({ ...x, tags: value }))}
                        mode="tags"
                        allowClear
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
            <Col xs={24} md={12} span={12} style={{ height: '100%' }}>
              <div
                style={{
                  maxWidth: 1300,
                }}
              >
                <Table
                  dataSource={items?.items ?? []}
                  columns={columns}
                  scroll={{ y: tableHeight }}
                  pagination={false}
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default CollectionsGroupDetails;
