import React from 'react';
import { Button, PageHeader } from 'antd';
import { restApi } from '../../../apis';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import qs from 'query-string';
import dayjs from 'dayjs';
import { EditOutlined, MenuOutlined } from '@ant-design/icons';
import { parseImageUri } from '../../../common/utils';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import DragSortingTable from '../../../components/DragSortingTable';
import produce from 'immer';
import { getEstimateTpls } from '../../../data/common';

const DragHandle = SortableHandle(() => (
  <MenuOutlined
    style={{
      cursor: 'grab',
      color: '#999',
    }}
  />
));

const SortableItem = SortableElement((props) => <tr {...props} />);
const SortableBody = SortableContainer((props) => <tbody {...props} />);

const ContentSubBannerIndex = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = React.useMemo(() => qs.parse(location?.search), [location?.search]);
  const [data, setData] = React.useState([]);

  // query 바뀔 때 마다 데이터 조회
  const loadData = React.useCallback(async () => {
    const { data } = await restApi.get('/homes/banners', {
      params: { type: 'PRODUCT' },
    });
    setData(data);
  }, [query]);

  React.useEffect(() => {
    loadData().catch(console.warn);
  }, [loadData]);

  const columns = [
    {
      title: '번호',
      dataIndex: 'id',
      align: 'center',
      width: 90,
      render: (v, __, index) => {
        return v;
      },
    },
    {
      title: '종류',
      dataIndex: 'type',
      align: 'center',
      width: 90,
      render: (v, __, index) => {
        return v;
      },
    },
    {
      title: '작성자',
      dataIndex: 'account',
      align: 'center',
      width: 150,
      render: (data) => {
        return data?.nickname;
      },
    },
    {
      title: '이미지',
      dataIndex: 'image_id',
      align: 'center',
      width: 150,
      render: (data) => {
        return <img src={parseImageUri(data, 100, 100)} style={{ width: 100, height: 100 }} />;
      },
    },
    {
      title: '내용',
      dataIndex: 'text',
      render: (data, record) => (
        <Link to={`/contents/subbanners/${record.id}`}>
          <a style={{ color: 'black' }}>{data || '제목이 없습니다.'}</a>
        </Link>
      ),
    },
    {
      title: '작성일자',
      dataIndex: 'created_at',
      align: 'center',
      width: 200,
      render: (data) => dayjs(data).format('YYYY-MM-DD HH:mm'),
    },
    {
      title: '조회',
      dataIndex: 'view_count',
      align: 'center',
      width: 80,
      render: (data) => {
        return `${data ?? 0}`;
      },
    },
    {
      title: '자세히보기',
      dataIndex: 'id',
      key: 'detail',
      align: 'center',
      width: 120,
      render: (data, record) => (
        <Link to={`/contents/subbanners/${record.id}`}>
          <a style={{ color: 'black' }}>자세히보기</a>
        </Link>
      ),
    },
  ];

  const handleSort = async (index, afterIndex) => {
    try {
      const items = produce(data, (draft) => {
        const item = draft.splice(index, 1);
        draft.splice(afterIndex, 0, item[0]);
      });
      setData(items);
      await restApi.put(`/homes/banners`, { ids: items?.map((v) => v.id) });
    } catch (e) {
      alert(e?.response?.data?.message || '서버와 연결이 올바르지 않습니다.');
    }
  };

  return (
    <div>
      <PageHeader className="site-page-header" title="제품배너" />
      <div style={{ position: 'relative' }}>
        <DragSortingTable
          rowKey={({ id }) => String(id)}
          dataSource={data}
          columns={columns}
          pagination={false}
          onSort={handleSort}
        />
        <div style={{ marginTop: 20, textAlign: 'right' }}>
          {/*<TableButtonWrap*/}
          {/*  position="left"*/}
          {/*  buttons={[{ icon: require('../../../assets/icon_save.png'), label: '엑셀 저장', onClick: xlsxSave }]}*/}
          {/*/>*/}
          {/*<TableButtonWrap*/}
          {/*  position="right"*/}
          {/*  buttons={[*/}
          {/*    { icon: require('../../../assets/icon_write.png'), label: '작성', link: '/contents/notice/create' },*/}
          {/*  ]}*/}
          {/*/>*/}
          <Link to={'/contents/subbanners/create'}>
            <Button icon={<EditOutlined />}>작성</Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ContentSubBannerIndex;
