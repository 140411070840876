import React, { useEffect, useState } from 'react';
import { Card, Form, Input, message, PageHeader, Switch } from 'antd';
import { restApi } from '../../../apis';
import { useParams } from 'react-router-dom';

const AccountsMakersDetails = (props) => {
  const params = useParams();

  const [data, setData] = useState({});
  useEffect(() => {
    const loadData = async () => {
      const { data } = await restApi.get(`/organizations/${params?.id}`);
      setData(data);
    };
    loadData().catch(console.warn);
  }, []);

  const handleSubmit = async () => {
    await restApi.put(`/organizations/${params.id}`, data);
    message.success('저장되었습니다.');
  };

  return (
    <div>
      <PageHeader className="site-page-header" title="유통/제조사" subTitle={'고객 관리'} />
      <Form layout={'horizontal'} onFinish={handleSubmit}>
        <div style={{ maxWidth: 1200 }}>
          <Card>
            <Form.Item label={'이름'} style={{ marginBottom: 20 }}>
              <Input value={data?.name} onChange={(e) => setData((x) => ({ ...x, title: e.target.value }))} />
            </Form.Item>
            <Form.Item label={'소개글'} style={{ marginBottom: 20 }}>
              <Input.TextArea
                value={data?.description}
                onChange={(e) => setData((x) => ({ ...x, description: e.target.value }))}
              />
            </Form.Item>
            <Form.Item label={'홈페이지'} style={{ marginBottom: 20 }}>
              <Input value={data?.homepage} onChange={(e) => setData((x) => ({ ...x, homepage: e.target.value }))} />
            </Form.Item>
            <Form.Item label={'견적가능여부'} style={{ marginBottom: 20 }}>
              <Switch
                checked={data?.calc_enabled}
                onClick={() => {
                  setData((x) => ({ ...x, calc_enabled: !x.calc_enabled }));
                }}
              />
            </Form.Item>
          </Card>
        </div>
      </Form>
    </div>
  );
};

export default AccountsMakersDetails;
