import React from 'react';
import { PageHeader, Table } from 'antd';
import SearchForm from '../components/datalist/SearchForm';

const HomeIndex = (props) => {
  return (
    <div>
      <PageHeader className="site-page-header" title="Title" subTitle="This is a subtitle" />
    </div>
  );
};

export default HomeIndex;
