import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { restApi } from '../apis';

const initialState = {
  themes: undefined,
  estimateTpls: undefined,
  estimateCategories: undefined,
  productCategories: undefined,
  productBrands: undefined,
};

export const getThemes = createAsyncThunk('commons/themes', async () => {
  const { data } = await restApi.get(`/commons/themes`);
  return data;
});

export const getEstimateTpls = createAsyncThunk('estimates/tpls', async () => {
  const { data } = await restApi.get(`/estimates/tpls`);
  return data;
});

export const getEstimateCategories = createAsyncThunk('estimates/categories', async () => {
  const { data } = await restApi.get(`/estimates/categories`);
  return data;
});

export const getProductCategories = createAsyncThunk('products/categories', async () => {
  const { data } = await restApi.get(`/product_categories`);
  return data;
});

export const getProductBrands = createAsyncThunk('products/brands', async () => {
  const { data } = await restApi.get(`/product_brands`);
  return data;
});

const CommonSlice = createSlice({
  name: 'Common',
  initialState,
  reducers: {
    init(state, action) {
      state = action.payload?.common || state;
    },
    initCommon(state, action) {
      state = action.payload || state;
    },
  },
  extraReducers: {
    [getThemes.fulfilled]: (state, action) => {
      state.themes = action.payload;
    },
    [getEstimateTpls.fulfilled]: (state, action) => {
      state.estimateTpls = action.payload;
    },

    [getEstimateCategories.fulfilled]: (state, action) => {
      state.estimateCategories = action.payload;
    },
    [getProductCategories.fulfilled]: (state, action) => {
      state.productCategories = action.payload;
    },
    [getProductBrands.fulfilled]: (state, action) => {
      state.productBrands = action.payload;
    },
  },
});

export const { init } = CommonSlice.actions;

export default CommonSlice.reducer;
