import React, { useState } from 'react';
import { Avatar, Col, InputNumber, Row } from 'antd';
import { comma, parseImageUri } from '../common/utils';

const ProductItem = ({ item: v, count, setCount, zoomEnabled }) => {
  const [show, setShow] = useState(undefined);

  const handleMouseEnter = (e) => {
    const r = e.target.getBoundingClientRect();
    setShow({
      left: r.x,// - (400 - r.width),
      top: r.y - (400 - r.height),
    });
  };

  return (
    <Row wrap={false} gutter={10}>
      <Col>
        {!!show && zoomEnabled && (
          <div
            style={{
              position: 'fixed',
              width: 400,
              height: 400,
              left: show?.left,
              top: show?.top,
              background: '#fff',
              boxShadow: '0px 0px 3px #333',
              zIndex: 999,
              pointerEvents: 'none',
            }}
          >
            <img src={parseImageUri(v?.image, 400, 400)} />
          </div>
        )}
        <div onMouseEnter={handleMouseEnter} onMouseLeave={() => setShow(undefined)}>
          <Avatar shape={'square'} src={parseImageUri(v?.image, 120, 120)} size={60} />
        </div>
      </Col>
      <Col flex={'1'}>
        <b style={{ display: 'block' }}>{v?.name}</b>
        <small>
          {!!v?.maker_name && <>M: {v?.maker_name}, </>}
          {!!v?.brand_name && <>B: {v?.brand_name}, </>}
          {!!v?.collection_name && <>C: {v?.collection_name}, </>}
          O: {v?.organization?.name}
        </small>
        <div style={{ fontSize: 12 }}>
          업체제공가 {comma(v?.corp_price)}원, 소비자가 {comma(v?.user_price)}원
        </div>
      </Col>
      {!!setCount && (
        <Col>
          <InputNumber value={count} onChange={(value) => setCount(value)} />
        </Col>
      )}
    </Row>
  );
};

export default ProductItem;
