import React, { useCallback, useEffect, useState } from 'react';
import { Button, PageHeader, Table } from 'antd';
import SearchForm from '../../../components/datalist/SearchForm';
import { restApi } from '../../../apis';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import qs from 'query-string';
import moment from 'moment';

const CollectionsGroupIndex = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [page, setPage] = useState(parseInt(qs.parse(location.search)?.page || '0', 10));
  const [data, setData] = useState();
  const [pageSize, setPageSize] = useState(20);
  const loadData = useCallback(async () => {
    const params = {
      limit: pageSize,
      ...qs.parse(location.search),
    };
    const { data } = await restApi.get(`/magazines/groups`, { params });
    setData(data);
  }, [location.search, pageSize]);
  useEffect(() => {
    loadData().catch(console.warn);
  }, [loadData]);

  const columns = [
    {
      title: '제목',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: '등록일',
      dataIndex: 'created_at',
      key: 'created_at',
      width: 160,
      align: 'center',
      render: (v) => moment(v).format('YYYY-MM-DD HH:mm'),
    },
  ];

  const searchField = [
    {
      width: 0.5,
      label: '일반검색',
      type: 'input',
      key: 'keyword',
    },
  ];

  const [tableHeight, setTableHeight] = useState(document.body.clientHeight - 60 - 180);
  useEffect(() => {
    const fn = () => {
      setTableHeight(document.body.clientHeight - 60 - 180);
    };
    window.addEventListener('resize', fn);
    return () => {
      window.removeEventListener('resize', fn);
    };
  }, []);

  const onRow = (record, rowIndex) => {
    return {
      onClick: (event) => {
        navigate(`/collections/group/${record.id}`);
      },
    };
  };

  return (
    <div>
      <PageHeader className="site-page-header" title="전시관리" subTitle="등록된 스타일 그룹을 관리합니다." />
      <SearchForm
        fields={searchField}
        extra={{ page }}
        rightDeco={
          <Link to={'/collections/group/create'}>
            <Button>등록</Button>
          </Link>
        }
      />
      <Table
        dataSource={data?.items ?? []}
        columns={columns}
        scroll={{ y: tableHeight }}
        pagination={{
          current: page + 1,
          total: data?.total_elements,
          pageSize: pageSize,
          pageSizeOptions: data?.total_elements >= 500 ? ['10', '20', '30', '50', '100'] : ['10', '20', '30', '50'],
          onShowSizeChange: (current, size) => setPageSize(size),
          showSizeChanger: true,
        }}
        onChange={(e) => {
          setPage(e.current - 1);
        }}
        onRow={onRow}
      />
    </div>
  );
};

export default CollectionsGroupIndex;
