import React, { useEffect, useMemo, useState } from 'react';
import { Button, PageHeader, Table } from 'antd';
import SearchForm from '../../../components/datalist/SearchForm';
import { restApi } from '../../../apis';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import qs from 'query-string';
import { getClientWidth } from '../../../common/utils';
import dayjs from 'dayjs';

const COLUMNS = [
  {
    title: '번호',
    dataIndex: 'id',
    align: 'center',
    width: 90,
    sorter: true,
  },
  {
    title: '명칭',
    dataIndex: 'name',
    minWidth: 200,
    sorter: true,
  },
  {
    title: '상품수',
    dataIndex: 'product_count',
    align: 'center',
    width: 90,
    sorter: true,
  },
  {
    title: '스타일수',
    dataIndex: 'collection_count',
    align: 'center',
    width: 90,
    sorter: true,
  },
  {
    title: '상태',
    dataIndex: 'state',
    align: 'center',
    width: 100,
    render: (d) => {
      return d === 'READY' ? '가입신청' : d === 'ACTIVE' ? '승인완료' : d;
    },
  },
  {
    title: '가입일',
    dataIndex: 'created_at',
    align: 'center',
    width: 160,
    render: (d) => dayjs(d).format('YYYY-MM-DD HH:mm'),
    sorter: true,
  },
];

const AccountsMakersIndex = () => {
  const location = useLocation();
  const query = React.useMemo(() => qs.parse(location?.search), [location?.search]);
  const [page, setPage] = useState(parseInt(qs.parse(location.search)?.page || '0', 10));
  const [data, setData] = React.useState();
  const [extra, setExtra] = useState();
  const [pageSize, setPageSize] = useState(20);
  const navigate = useNavigate();
  // query 바뀔 때 마다 데이터 조회
  const loadData = React.useCallback(async () => {
    const params = {
      ...query,
      type: 'P',
      limit: pageSize,
    };
    const { data } = await restApi.get('/organizations', { params });
    setData(data);
  }, [query, pageSize]);

  React.useEffect(() => {
    loadData().catch(console.warn);
  }, [loadData]);

  const [columns, tableWidth] = useMemo(() => {
    const o = [...COLUMNS];
    const width = o.reduce((a, c) => a + (c.width || c.minWidth || 300), 0);
    return [o, width];
  }, []);

  const [tableScroll, setTableScroll] = useState();
  useEffect(() => {
    const handle = () => {
      if (tableWidth < getClientWidth()) {
        setTableScroll(undefined);
      } else {
        setTableScroll({ x: tableWidth });
      }
    };

    handle();
  }, [tableWidth]);

  const searchField = [
    {
      width: 0.5,
      label: '종류',
      type: 'select',
      key: 'type',
      items: [
        { label: '아파트', value: 'APT' },
        { label: '오피스텔', value: 'OPST' },
      ],
      submit: true,
    },
    {
      width: 0.5,
      label: '검색어',
      type: 'input',
      key: 'keyword',
    },
  ];
  const [tableHeight, setTableHeight] = useState(document.body.clientHeight - 60 - 180);
  useEffect(() => {
    const fn = () => {
      setTableHeight(document.body.clientHeight - 60 - 180);
    };
    window.addEventListener('resize', fn);
    return () => {
      window.removeEventListener('resize', fn);
    };
  }, []);
  // 테이블 페이지네이션
  // const pagination = {
  //   position: ['bottomCenter'],
  //   total: data?.total_elements,
  //   pageSize: LIMIT,
  //   current: data?.page + 1,
  //   onChange: (p) => setExtra({ page: p - 1 }),
  // };
  const onRow = (record, rowIndex) => {
    return {
      onClick: (event) => {
        navigate(`/accounts/makers/${record.id}`);
      },
    };
  };

  return (
    <div>
      <PageHeader className="site-page-header" title="유통/제조사" subTitle={'고객 관리'} />
      <SearchForm fields={searchField} extra={{ page }} />
      <Table
        scroll={{ y: tableHeight }}
        dataSource={data?.items}
        columns={columns}
        pagination={{
          current: page + 1,
          total: data?.total_elements,
          pageSize: pageSize,
          pageSizeOptions: ['10', '20', '30', '50'],
          onShowSizeChange: (current, size) => setPageSize(size),
          showSizeChanger: true,
        }}
        onChange={(e) => {
          setPage(e.current - 1);
        }}
        onRow={onRow}
      />
    </div>
  );
};

export default AccountsMakersIndex;
