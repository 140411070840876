/* eslint jsx-a11y/mouse-events-have-key-events: 0 */
import React, { Component, createRef, useCallback, useMemo, useRef, useState } from 'react';
import styles from './style.css';

const Zoom = (props) => {
  const imageRef = useRef();

  const [pause, setPause] = useState(false);
  const [state, setState] = useState({
    zoom: false,
    mouseX: null,
    mouseY: null,
  });

  const handleMouseOver = useCallback(() => {
    if (pause) return;
    setState((x) => ({
      ...x,
      zoom: true,
    }));
  }, [pause]);

  const handleMouseOut = useCallback(() => {
    if (pause) return;
    setState((draft) => ({
      ...draft,
      zoom: false,
    }));
  }, [pause]);

  const handleMouseMovement = useCallback(
    (e) => {
      if (pause) return;
      const { left: offsetLeft, top: offsetTop } = imageRef.current.getBoundingClientRect();

      const {
        current: {
          style: { height, width },
        },
      } = imageRef;

      const x = ((e.pageX - offsetLeft) / parseInt(width, 10)) * 100;
      const y = ((e.pageY - offsetTop) / parseInt(height, 10)) * 100;

      setState((draft) => ({
        ...draft,
        mouseX: x,
        mouseY: y,
      }));
    },
    [pause],
  );

  const { height, img, transitionTime, width } = props;
  const outerDivStyle = useMemo(
    () => ({
      height: `${height}px`,
      width: `${width}px`,
      overflow: 'hidden',
      position: 'relative',
    }),
    [width, height],
  );

  const innerDivStyle = useMemo(
    () => ({
      height: `${height}px`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'contain',
      transition: `transform ${transitionTime}s ease-out`,
      backgroundImage: `url('${img}')`,
    }),
    [img, height, transitionTime],
  );

  const { mouseX, mouseY, zoom } = state;
  const { zoomScale } = props;
  const transform = {
    transformOrigin: `${mouseX}% ${mouseY}%`,
  };

  return (
    <div
      style={outerDivStyle}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      onMouseMove={handleMouseMovement}
      ref={imageRef}
      onClick={() => setPause((x) => !x)}
    >
      <div
        style={{
          ...transform,
          ...innerDivStyle,
          transform: zoom ? `scale(${zoomScale})` : 'scale(1.0)',
        }}
        className={styles.zoomImg}
      />
      {!!pause && (
        <div
          style={{
            padding: 10,
            background: 'rgba(0,0,0,0.7)',
            color: '#fff',
            fontSize: 12,
            fontWeight: 'bold',
            position: 'absolute',
            top: 10,
            left: 10,
          }}
        >
          잠금상태
        </div>
      )}
    </div>
  );
};

export default Zoom;
