import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Avatar,
  Button,
  Divider,
  Dropdown,
  Form,
  Image,
  Input,
  InputNumber,
  Menu,
  Modal,
  notification,
  PageHeader,
  Space,
  Switch,
  Table,
} from 'antd';
import SearchForm from '../../../components/datalist/SearchForm';
import { shallowEqual, useSelector } from 'react-redux';
import qs from 'query-string';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { restApi } from '../../../apis';
import moment from 'moment';
import { comma, parseCategory, parseImageUri } from '../../../common/utils';

const LIMIT = 50;

const COLUMN = [
  {
    key: 'id',
    dataIndex: 'id',
    align: 'center',
    width: '4%',
    title: '번호',
    fixed: 'left',
  },
  {
    key: 'image',
    dataIndex: 'image',
    title: '이미지',
    width: '7%',
    align: 'center',
    fixed: 'left',
    render: (img) => {
      return <Image style={{ width: 70, height: 70 }} src={parseImageUri(img, 1000, 1000)} />;
      // <Avatar shape="square" size={60} src={parseImageUri(img, 120, 120)} />;
    },
  },
  {
    key: 'category',
    dataIndex: 'category',
    title: '카테고리',
    width: '7%',
  },
  {
    key: 'maker_name',
    dataIndex: 'maker_name',
    title: '제조사',
    width: '10%',
    render: (data, row) => {
      return (
        <>
          M. {row.maker_name}
          <br />
          B. {row.brand_name}
          <br />
          C. {row.collection_name}
          <br />
        </>
      );
    },
  },
  {
    key: 'name',
    dataIndex: 'name',
    title: '이름',
    minWidth: 300,
    // render: (d, row) => <Link to={`/products/items/${row.id}`}>{d}</Link>,
  },
  {
    key: 'organization',
    dataIndex: 'organization',
    title: '등록자',
    width: '10%',
    align: 'left',
    render: (d) => {
      return (
        <Space>
          <Avatar src={parseImageUri(d, 80, 80)} />
          <span>{d.name}</span>
        </Space>
      );
    },
  },
  {
    key: 'state',
    dataIndex: 'state',
    title: '상태',
    width: '5%',
    align: 'center',
  },
  {
    key: 'origin_price',
    dataIndex: 'origin_price',
    title: '제공단가',
    width: '7%',
    align: 'right',
    render: (d, r) => (
      <>
        {String(d ?? 0).replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '원'}
        <br />
        <small>({comma(r.min_sale_price)})</small>
      </>
    ),
  },
  {
    key: 'corp_price',
    dataIndex: 'corp_price',
    title: '업체가',
    width: '7%',
    align: 'right',
    render: (d, r) => (
      <>
        {String(d ?? 0).replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '원'}
        <br />
        <small>({r.corp_per ?? 0}%)</small>
      </>
    ),
  },
  {
    key: 'user_price',
    dataIndex: 'user_price',
    title: '소비자가',
    width: '7%',
    align: 'right',
    render: (d, r) => (
      <>
        {String(d ?? 0).replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '원'}
        <br />
        <small>
          ({r.corp_per ?? 0}% + {r.user_per ?? 0}%)
        </small>
      </>
    ),
  },
  {
    key: 'active',
    dataIndex: 'active',
    title: '발주가능',
    width: '%',
    align: 'center',
  },
  {
    key: 'created_at',
    dataIndex: 'created_at',
    title: '등록일',
    width: '10%',
    align: 'center',
    render: (d) => {
      return moment(d).format('YYYY-MM-DD HH:mm');
    },
  },
];

const ProductsItemsIndex = (props) => {
  const { productCategories } = useSelector((s) => s.common, shallowEqual);

  const treeData = useMemo(() => {
    const parseItem = (d) => {
      return {
        value: d.code,
        label: d.label,
        children: !d.children?.length ? undefined : d.children?.map((v) => parseItem(v)),
      };
    };

    return productCategories?.map((v) => parseItem(v));
  }, [productCategories]);

  const [rowKey, setRowKey] = useState([]);
  const [showRate, setShowRate] = useState(undefined);
  const [pageSize, setPageSize] = useState(20);
  const location = useLocation();
  const navigate = useNavigate();
  const page = parseInt(qs.parse(location.search)?.page || '0', 10);
  const setPage = (p) => {
    const query = qs.parse(location.search);
    query.page = p;
    navigate('?' + qs.stringify(query), { replace: true });
  };
  // const [page, setPage] = useState(parseInt(qs.parse(location.search)?.page || '0', 10));
  const [data, setData] = useState();
  const loadData = useCallback(async () => {
    const params = {
      limit: pageSize,
      ...qs.parse(location.search),
    };
    console.log({ params });
    const { data } = await restApi.get(`/products`, { params });
    setData(data);
    // setRowKey([]);
  }, [location.search, pageSize]);

  useEffect(() => {
    loadData().catch(console.warn);
  }, [loadData]);

  const searchField = [
    {
      width: 1,
      label: '상태',
      type: 'checkbox',
      key: 'state',
      submit: true,
      items: [
        { label: '대기중', value: 'READY' },
        { label: '게시중', value: 'ACTIVE' },
        { label: '사용안함', value: 'DENINED' },
      ],
    },
    {
      width: 0.5,
      label: '카테고리',
      type: 'tree_select',
      key: 'categories',
      treeData: treeData,
      submit: true,
    },
    {
      width: 0.5,
      label: '검색어',
      type: 'input',
      key: 'keyword',
    },
  ];

  const [loading, setLoading] = useState(false);
  const processField = useCallback(
    async (ids, field, value) => {
      try {
        setLoading(true);
        await restApi.put(`/products/fields`, { ids, field, value });
        await loadData();
        notification.success({ message: '수정완료' });
        setShowRate(undefined);
      } catch (e) {
        notification.error({ message: e?.response?.data?.message || '서버 연결 실패' });
      }
      setLoading(false);
    },
    [loadData],
  );

  const [columns, tableWidth] = useMemo(() => {
    const o = [...COLUMN];
    o[2].render = (d) => parseCategory(productCategories, d, <br />);
    o[10].render = (d, r) => (
      <Switch
        checked={d}
        onChange={(v) => {
          processField([r.id], 'active', v ? 'Y' : 'N').catch(console.warn);
        }}
        loading={loading}
      />
    );

    let tableWidth = o.reduce((a, c) => a + (c.width ?? c.minWidth ?? 200), 0);
    return [o, tableWidth];
  }, [productCategories, loading, processField]);

  const rateForm = useRef();
  const handleRateSubmit = async () => {
    if ((showRate?.corp_per ?? 10) < 8) {
      alert('최소 8% 이상 입력하세요.');
      return;
    }

    try {
      setLoading(true);
      await restApi.put(`/products/rates`, {
        ids: rowKey?.map((v) => parseInt(v, 10)),
        user_per: showRate?.user_per ?? 0,
        corp_per: showRate?.corp_per ?? 10,
      });
      await loadData();
      notification.success({ message: '수정완료' });
      setShowRate(undefined);
    } catch (e) {
      notification.error({ message: e?.response?.data?.message || '서버 연결 실패' });
    }
    setLoading(false);
  };

  const handleMenu = useCallback(
    async ({ key }) => {
      const args = key.split(':');
      await processField(
        rowKey?.map((v) => parseInt(v, 10)),
        args[0],
        args[1],
      );
      setRowKey([]);
    },
    [rowKey, processField],
  );
  const [tableHeight, setTableHeight] = useState(document.body.clientHeight - 120 - 180);
  useEffect(() => {
    const fn = () => {
      setTableHeight(document.body.clientHeight - 120 - 180);
    };
    window.addEventListener('resize', fn);
    return () => {
      window.removeEventListener('resize', fn);
    };
  }, []);

  const overlay = useMemo(() => {
    const items = data?.items?.filter((v) => rowKey.includes(String(v.id)));
    const readyCount = items?.filter((v) => v.state === 'READY')?.length;
    const unsetCount = items?.filter((v) => v.corp_per <= 0)?.length;

    if (unsetCount) return undefined;

    return (
      <Menu onClick={handleMenu}>
        {!unsetCount && (
          <>
            <Menu.Item key="state:ACTIVE">상태: 게시</Menu.Item>
            <Menu.Item key="state:READY">상태: 대기</Menu.Item>
            <Menu.Item key="state:DENINED">상태: 사용안함</Menu.Item>
          </>
        )}
        {!unsetCount && !readyCount && <Menu.Divider />}
        {!readyCount && (
          <>
            <Menu.Item key="active:Y">발주가능</Menu.Item>
            <Menu.Item key="active:N">발주불가</Menu.Item>
          </>
        )}
      </Menu>
    );
  }, [rowKey, data, handleMenu]);

  const onRow = (record, rowIndex) => {
    return {
      onClick: () => {
        navigate(`/products/items/${record.id}`);
      },
    };
  };

  return (
    <div>
      <Modal
        visible={!!showRate}
        title={'비율설정'}
        onOk={() => rateForm?.current?.submit()}
        onCancel={() => setShowRate(undefined)}
        confirmLoading={loading}
      >
        <Form ref={rateForm} onFinish={handleRateSubmit}>
          <Button htmlType={'submit'} style={{ position: 'absolute', opacity: 0, width: 0, height: 0 }} />
          <Form.Item label={'업체가 추가비율'} style={{ marginBottom: 10 }}>
            <InputNumber
              style={{ width: '100%' }}
              suffix={'%'}
              placeholder={'10'}
              value={showRate?.corp_per}
              onChange={(value) => {
                setShowRate((x) => ({ ...x, corp_per: value }));
              }}
              addonAfter={'%'}
            />
          </Form.Item>
          <Form.Item label={'소비자가 추가비율'}>
            <InputNumber
              style={{ width: '100%' }}
              suffix={'%'}
              placeholder={'0'}
              value={showRate?.user_per}
              onChange={(value) => {
                setShowRate((x) => ({ ...x, user_per: value }));
              }}
              addonAfter={'%'}
            />
          </Form.Item>
        </Form>
      </Modal>
      <PageHeader className="site-page-header" title="등록상품 관리" subTitle="상품 관리" />

      <SearchForm
        fields={searchField}
        extra={{ page }}
        rightDeco={
          !!rowKey?.length && (
            <Space>
              {!!overlay && (
                <Dropdown overlay={overlay}>
                  <Button>관리</Button>
                </Dropdown>
              )}
              <Button onClick={() => setShowRate({})}>비율설정</Button>
            </Space>
          )
        }
      />
      <Table
        scroll={{ y: tableHeight }}
        rowSelection={{
          selectedRowKeys: rowKey,
          onChange: (rowKey) => {
            setRowKey(rowKey);
            console.log(rowKey);
          },
        }}
        rowKey={(r) => `${r.id}`}
        dataSource={data?.items ?? []}
        columns={columns}
        pagination={{
          current: page + 1,
          total: data?.total_elements,
          pageSize: pageSize,
          pageSizeOptions: data?.total_elements >= 500 ? ['10', '20', '30', '50', '100'] : ['10', '20', '30', '50'],
          onShowSizeChange: (current, size) => setPageSize(size),
          showSizeChanger: true,
        }}
        onChange={(e) => {
          setPage(e.current - 1);
        }}
        onRow={onRow}
      />
    </div>
  );
};

export default ProductsItemsIndex;
