import React, { useRef } from 'react';
import { Button, Form, Input, Modal } from 'antd';
import SingleImageUpload from '../SingleImageUpload';
import produce from 'immer';

const EstimateCategoryForm = ({ modify, setModify, loading, onSubmit, onDelete }) => {
  const form = useRef();

  return (
    <Modal
      visible={!!modify}
      title={modify?.id ? '카테고리 수정' : '카테고리 등록'}
      onCancel={() => setModify(undefined)}
      destroyOnClose
      maskClosable={false}
      confirmLoading={loading}
      closable={!loading}
      footer={[
        modify?.id && (
          <Button onClick={onDelete} danger>
            삭제
          </Button>
        ),
        <Button key="submit" type="primary" loading={loading} onClick={() => form.current.submit()}>
          저장
        </Button>,
        <Button
          key="back"
          onClick={() => {
            setModify(undefined);
          }}
        >
          취소
        </Button>,
      ]}
    >
      <Form onFinish={onSubmit} ref={form}>
        <Form.Item label={'명칭'} style={{ marginBottom: 20 }}>
          <Input
            value={modify?.name}
            onChange={(e) => {
              const value = e.target.value;
              setModify((x) => ({
                ...x,
                name: value,
              }));
            }}
          />
        </Form.Item>
        <Form.Item label={'이미지'} style={{ marginBottom: 20 }}>
          <SingleImageUpload
            id={modify?.desc_image}
            onChange={(v) => {
              setModify((x) => ({
                ...x,
                desc_image: v,
              }));
            }}
          />
        </Form.Item>
        <Form.Item label={'설명'} style={{ marginBottom: 20 }}>
          <Input.TextArea
            value={modify?.description}
            onChange={(e) => {
              const value = e.target.value;
              setModify((x) => ({
                ...x,
                description: value,
              }));
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EstimateCategoryForm;
