import React, { useEffect, useMemo, useState } from 'react';
import { Button, Card, Col, Form, Input, notification, PageHeader, Row } from 'antd';
import { restApi } from '../../../apis';
import { getClientWidth } from '../../../common/utils';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import DragSortingTable from '../../../components/DragSortingTable';
import { getEstimateCategories } from '../../../data/common';
import produce from 'immer';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import EstimateCategoryForm from '../../../components/settings/EstimateCategoryForm';

const COLUMNS = [
  {
    title: '번호',
    dataIndex: 'id',
    align: 'center',
    width: 90,
    render: (v, __, index) => {
      return v;
    },
  },
  {
    title: '이름',
    dataIndex: 'name',
    width: 200,
  },
  {
    title: '설명',
    key: 'description',
    dataIndex: 'description',
  },
  {
    key: 'created_at',
    title: '등록일',
    dataIndex: 'created_at',
    width: 160,
    align: 'center',
    render: (r) => moment(r).format('YYYY-MM-DD HH:mm'),
  },
  {
    title: '',
    dataIndex: 'id',
    key: 'more',
    width: 120,
    align: 'center',
    render: (id, r) => {
      return <Button size={'small'}>자세히보기</Button>;
    },
  },
];

const SettingsEstimateCategoriesDetails = (factory, deps) => {
  const dispatch = useDispatch();
  const params = useParams();

  const { estimateCategories } = useSelector((s) => s.common, shallowEqual);
  const item = useMemo(() => {
    console.log(params.id, estimateCategories);
    return estimateCategories?.find((v) => String(v.id) === params?.id) ?? {};
  }, [estimateCategories, params?.id]);

  const [data, setData] = useState(item);
  const [children, setChildren] = React.useState(item?.items ?? []);

  useEffect(() => {
    setData(item);
    setChildren(item?.items);
  }, [item]);

  const [columns, tableWidth] = useMemo(() => {
    const o = [...COLUMNS];

    o[COLUMNS.length - 1].render = (_, r) => {
      return (
        <Button onClick={() => setModify(r)} size={'small'}>
          자세히보기
        </Button>
      );
    };

    const width = o.reduce((a, c) => a + (c.width || c.minWidth || 300), 0);
    return [o, width];
  }, []);

  const [tableScroll, setTableScroll] = useState();
  useEffect(() => {
    const handle = () => {
      if (tableWidth < getClientWidth()) {
        setTableScroll(undefined);
      } else {
        setTableScroll({ x: tableWidth });
      }
    };

    handle();
  }, [tableWidth]);

  const handleSort = async (index, afterIndex) => {
    try {
      const items = produce(children, (draft) => {
        const item = draft.splice(index, 1);
        draft.splice(afterIndex, 0, item[0]);
      });
      setChildren(items);
      await restApi.put(`/estimates/categories`, { ids: items?.map((v) => v.id) });
    } catch (e) {
      alert(e?.response?.data?.message || '서버와 연결이 올바르지 않습니다.');
    }
    await dispatch(getEstimateCategories());
  };

  const handleSubmit = async () => {
    await restApi.put(`/estimates/categories/${data.id}`, data);
    await dispatch(getEstimateCategories());
    notification.success({ message: '저장 완료' });
  };

  const [modify, setModify] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChildDelete = async () => {
    setLoading(true);
    try {
      await restApi.delete(`/estimates/categories/${modify.id}`);
      await dispatch(getEstimateCategories());
      notification.success({ message: '삭제 완료' });
      setModify(undefined);
    } catch (e) {
      notification.error({ message: e?.response?.data?.message || '서버 연결 실패' });
    }
    setLoading(false);
  };

  const handleChildSubmit = async () => {
    setLoading(true);
    try {
      if (modify?.id) {
        await restApi.put(`/estimates/categories/${modify.id}`, modify);
      } else {
        await restApi.post(`/estimates/categories`, modify);
      }
      await dispatch(getEstimateCategories());
      notification.success({ message: '저장 완료' });
      setModify(undefined);
    } catch (e) {
      notification.error({ message: e?.response?.data?.message || '서버 연결 실패' });
    }

    setLoading(false);
  };

  return (
    <div>
      <EstimateCategoryForm
        modify={modify}
        setModify={setModify}
        loading={loading}
        onDelete={handleChildDelete}
        onSubmit={handleChildSubmit}
      />
      <PageHeader className="site-page-header" title="공종 카테고리" subTitle={'설정'} />
      <Form layout={'horizontal'} onFinish={handleSubmit}>
        <div style={{ maxWidth: 1200 }}>
          <Card>
            <Form.Item label={'제목'} style={{ marginBottom: 20 }}>
              <Input value={data?.name} onChange={(e) => setData((x) => ({ ...x, name: e.target.value }))} />
            </Form.Item>
            <Form.Item label={'내용'} style={{ marginBottom: 20 }}>
              <Input.TextArea
                value={data?.description}
                onChange={(e) => setData((x) => ({ ...x, description: e.target.value }))}
              />
            </Form.Item>
          </Card>
          <div style={{ marginTop: 10 }}>
            <Row>
              <Col flex={'1'}>
                <Button onClick={() => setModify({ parent: data.id })}>추가</Button>
              </Col>
              <Col>
                <Button htmlType={'submit'} type={'primary'}>
                  저장
                </Button>
              </Col>
            </Row>
          </div>
          <Card style={{ marginTop: 20 }}>
            <DragSortingTable
              rowKey={({ id }) => id}
              scroll={tableScroll}
              dataSource={children}
              columns={columns}
              pagination={false}
              emptyText={'공종, 구분을 선택해주세요.'}
              onSort={handleSort}
            />
          </Card>
        </div>
      </Form>
    </div>
  );
};

export default SettingsEstimateCategoriesDetails;
