import React, { useCallback, useEffect, useState } from 'react';
import { Button, Image, PageHeader, Table } from 'antd';
import SearchForm from '../../../components/datalist/SearchForm';
import { getAPIHost, restApi } from '../../../apis';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import qs from 'query-string';
import { POSITION_LABELS } from '../../../common/define';
import moment from 'moment';

const CollectionsMagazinesIndex = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [page, setPage] = useState(parseInt(qs.parse(location.search)?.page || '0', 10));
  const [data, setData] = useState();
  const [pageSize, setPageSize] = useState(20);
  const loadData = useCallback(async () => {
    const params = {
      limit: pageSize,
      ...qs.parse(location.search),
    };
    const { data } = await restApi.get(`/magazines`, { params });
    setData(data);
  }, [location.search, pageSize]);
  useEffect(() => {
    loadData().catch(console.warn);
  }, [loadData]);

  const columns = [
    {
      title: '이미지',
      dataIndex: 'image',
      key: 'image',
      width: 80,
      align: 'center',
      render: (id, text) => <Image style={{ width: 50, height: 50 }} src={getAPIHost() + '/files/' + id} />,
    },
    {
      title: '종목',
      dataIndex: 'positions',
      key: 'positions',
      width: 180,
      render: (v) => v?.map((o) => POSITION_LABELS[o])?.join(','),
    },
    {
      title: '제목',
      dataIndex: 'title',
      key: 'title',
      render: (data, row) => <Link to={`/collections/magazines/${row.id}`}>{data}</Link>,
    },
    {
      title: '공개',
      dataIndex: 'active',
      key: 'active',
      width: 80,
      align: 'center',
      render: (v) => (v ? <b style={{ color: '#333' }}>공개</b> : <span style={{ color: '#999' }}>비공개</span>),
    },
    {
      title: '승인여부',
      dataIndex: 'review_user',
      key: 'review_user',
      width: 170,
      align: 'center',
      render: (v, r) => v?.id && `${v.nickname} (${moment(r.review_at).format('YY-MM-DD')})`,
    },
    {
      title: '등록일',
      dataIndex: 'created_at',
      key: 'created_at',
      width: 160,
      align: 'center',
      render: (v) => moment(v).format('YYYY-MM-DD HH:mm'),
    },
    // {
    //   title: '',
    //   dataIndex: 'id',
    //   key: 'more',
    //   width: 120,
    //   align: 'center',
    //   render: (id) => {
    //     return (
    //       <Link to={`/collections/magazines/${id}`}>
    //         <Button size={'small'}>자세히보기</Button>
    //       </Link>
    //     );
    //   },
    // },
  ];

  const searchField = [
    {
      width: 0.5,
      label: '일반검색',
      type: 'input',
      key: 'keyword',
    },
  ];

  const [tableHeight, setTableHeight] = useState(document.body.clientHeight - 60 - 180);
  useEffect(() => {
    const fn = () => {
      setTableHeight(document.body.clientHeight - 60 - 180);
    };
    window.addEventListener('resize', fn);
    return () => {
      window.removeEventListener('resize', fn);
    };
  }, []);

  // const onRow = (record, rowIndex) => {
  //   return {
  //     onClick: (event) => {
  //       navigate(`/collections/magazines/${record.id}`);
  //     },
  //   };
  // };

  return (
    <div>
      <PageHeader className="site-page-header" title="스타일관리" subTitle="등록된 스타일을 관리합니다." />
      <SearchForm
        fields={searchField}
        extra={{ page }}
        rightDeco={
          <Link to={'/collections/magazines/create'}>
            <Button>등록</Button>
          </Link>
        }
      />
      <Table
        dataSource={data?.items ?? []}
        columns={columns}
        scroll={{ y: tableHeight }}
        pagination={{
          current: page + 1,
          total: data?.total_elements,
          pageSize: pageSize,
          pageSizeOptions: data?.total_elements >= 500 ? ['10', '20', '30', '50', '100'] : ['10', '20', '30', '50'],
          onShowSizeChange: (current, size) => setPageSize(size),
          showSizeChanger: true,
        }}
        onChange={(e) => {
          setPage(e.current - 1);
        }}
        // onRow={onRow}
      />
    </div>
  );
};

export default CollectionsMagazinesIndex;
