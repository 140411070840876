import DraggableBodyRow from './DraggableBodyRow';
import { useCallback, useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Table } from 'antd';
import qs from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';
import SearchForm from './datalist/SearchForm';

// const LIMIT = 50;
const DragSortingTable = (props) => {
  const components = {
    body: {
      row: DraggableBodyRow,
    },
  };

  const navigate = useNavigate();

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      props?.onSort?.(dragIndex, hoverIndex);
    },
    [props?.onSort],
  );
  const [tableHeight, setTableHeight] = useState(document.body.clientHeight - 60 - 180);
  useEffect(() => {
    const fn = () => {
      setTableHeight(document.body.clientHeight - 60 - 180);
    };
    window.addEventListener('resize', fn);
    return () => {
      window.removeEventListener('resize', fn);
    };
  }, []);
  const searchField = [
    {
      width: 0.5,
      label: '키워드',
      type: 'input',
      key: 'keyword',
    },
  ];

  // const onRow = (record, rowIndex) => {
  //   return {
  //     onClick: (event) => {
  //       navigate(`/accounts/users/${record.id}`);
  //     },
  //   };
  // };
  const handleClick = (record) => {
    console.log(record);
    props.onRowClick?.(record);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      {/*<SearchForm fields={searchField} extra={props.extra} />*/}
      <Table
        {...props}
        components={components}
        scroll={{ y: tableHeight }}
        onRow={(record, index) => ({
          index,
          moveRow,
          onClick: () => handleClick(record),
        })}
        onChange={props.handleChange}
      />
    </DndProvider>
  );
};

export default DragSortingTable;
