import React from 'react';
import { Button, PageHeader, Table } from 'antd';
import SearchForm from '../../../components/datalist/SearchForm';
import { restApi } from '../../../apis';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import qs from 'query-string';
import dayjs from 'dayjs';
import { EditOutlined } from '@ant-design/icons';
// const LIMIT = 50;
const ContentNoticeIndex = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [page, setPage] = React.useState(parseInt(qs.parse(location.search)?.page || '0', 10));
  const query = React.useMemo(() => qs.parse(location?.search), [location?.search]);
  const [data, setData] = React.useState();
  const [pageSize, setPageSize] = React.useState(20);
  // query 바뀔 때 마다 데이터 조회
  const loadData = React.useCallback(async () => {
    const params = {
      ...query,
      type: 'NOTICE',
      limit: pageSize,
    };

    params.dir = query.sort === 'id' ? 'asc' : 'desc';

    if (query.field === 'name') {
      params.name = query.keyword;
    } else if (query.field === 'phone') {
      params.phone = query.keyword;
    }

    const { data } = await restApi.get('/posts', {
      params,
    });
    setData(data);
  }, [query, pageSize]);

  React.useEffect(() => {
    loadData();
  }, [loadData]);

  const columns = [
    {
      title: '번호',
      dataIndex: 'id',
      align: 'center',
      width: 90,
      render: (v, __, index) => {
        return v;
      },
    },
    {
      title: '공지대상',
      dataIndex: 'role_type',
      align: 'center',
      width: 80,
      render: (role_type) => {
        return role_type === 'M' ? '장인' : role_type === 'T' ? '기사' : '고객';
      },
    },
    {
      title: '작성자',
      dataIndex: 'account',
      align: 'center',
      width: 200,
      render: (data) => {
        return data.nickname;
      },
    },
    {
      title: '제목',
      dataIndex: 'title',
      align: 'center',
      render: (data, record) => (
        <Link to={`/contents/notice/details/${record.id}`}>
          <a style={{ color: 'black' }}>{data || '제목이 없습니다.'}</a>
        </Link>
      ),
    },
    {
      title: '작성일자',
      dataIndex: 'created_at',
      align: 'center',
      width: 200,
      render: (data) => dayjs(data).format('YYYY-MM-DD HH:mm'),
    },
    {
      title: '조회',
      dataIndex: 'view_count',
      align: 'center',
      width: 120,
      render: (data) => {
        return `${data ?? 0}`;
      },
    },
    {
      title: '자세히보기',
      dataIndex: 'id',
      key: 'detail',
      align: 'center',
      width: 120,
      render: (data, record) => (
        <Link to={`/contents/notice/details/${record.id}`}>
          <a style={{ color: 'black' }}>자세히보기</a>
        </Link>
      ),
    },
  ];
  // 테이블 페이지네이션
  // const pagination = {
  //   position: ['bottomCenter'],
  //   total: data?.total_elements,
  //   pageSize: 10,
  //   current: data?.page + 1,
  //   onChange: async (p) => {
  //     navigate('?' + qs.stringify({ ...query, page: p - 1 }), { replace: true });
  //   },
  // };
  const [tableHeight, setTableHeight] = React.useState(document.body.clientHeight - 120 - 180);
  React.useEffect(() => {
    const fn = () => {
      setTableHeight(document.body.clientHeight - 120 - 180);
    };
    window.addEventListener('resize', fn);
    return () => {
      window.removeEventListener('resize', fn);
    };
  }, []);

  return (
    <div>
      <PageHeader className="site-page-header" title="공지사항" />
      <SearchForm
        fields={[
          {
            width: 0.5,
            label: '일반검색',
            type: 'input',
            key: 'keyword',
          },
        ]}
        extra={{ page }}
      />

      <Table
        rowKey={({ id }) => id}
        // rowSelection={rowSelection}
        dataSource={data?.items}
        columns={columns}
        scroll={{ y: tableHeight }}
        pagination={{
          current: page + 1,
          total: 100,
          pageSize: pageSize,
          pageSizeOptions: ['10', '20', '30', '50'],
          onShowSizeChange: (current, size) => setPageSize(size),
        }}
        onChange={(e) => {
          setPage(e.current - 1);
        }}
      />
      <div style={{ marginTop: 20, textAlign: 'right' }}>
        {/*<TableButtonWrap*/}
        {/*  position="left"*/}
        {/*  buttons={[{ icon: require('../../../assets/icon_save.png'), label: '엑셀 저장', onClick: xlsxSave }]}*/}
        {/*/>*/}
        {/*<TableButtonWrap*/}
        {/*  position="right"*/}
        {/*  buttons={[*/}
        {/*    { icon: require('../../../assets/icon_write.png'), label: '작성', link: '/contents/notice/create' },*/}
        {/*  ]}*/}
        {/*/>*/}
        <Link to={'/contents/notices/create'}>
          <Button icon={<EditOutlined />}>작성</Button>
        </Link>
      </div>
    </div>
  );
};

export default ContentNoticeIndex;
