import React, { useEffect, useMemo, useState } from 'react';
import { Button, PageHeader } from 'antd';
import { restApi } from '../../../apis';
import { getClientWidth } from '../../../common/utils';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import DragSortingTable from '../../../components/DragSortingTable';
import { getEstimateCategories } from '../../../data/common';
import produce from 'immer';
import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';

const COLUMNS = [
  {
    title: '번호',
    dataIndex: 'id',
    align: 'center',
    width: 90,
    render: (v, __, index) => {
      return v;
    },
  },
  {
    title: '이름',
    dataIndex: 'name',
    width: 200,
  },
  {
    title: '설명',
    key: 'description',
    dataIndex: 'description',
  },

  {
    title: '하위갯수',
    dataIndex: 'items',
    width: 180,
    key: 'items',
    align: 'center',
    render: (r) => r?.length ?? 0,
  },
  {
    key: 'created_at',
    title: '등록일',
    dataIndex: 'created_at',
    width: 160,
    align: 'center',
    render: (r) => moment(r).format('YYYY-MM-DD HH:mm'),
  },
];

const SettingsEstimateCategoriesIndex = () => {
  const dispatch = useDispatch();
  const [pageSize, setPageSize] = useState(20);
  const { estimateCategories } = useSelector((s) => s.common, shallowEqual);
  const [data, setData] = React.useState(estimateCategories);

  useEffect(() => {
    setData(estimateCategories);
  }, [estimateCategories]);

  const [columns, tableWidth] = useMemo(() => {
    const o = [...COLUMNS];

    const width = o.reduce((a, c) => a + (c.width || c.minWidth || 300), 0);
    return [o, width];
  }, []);

  const [tableHeight, setTableHeight] = useState(document.body.clientHeight - 60 - 180);
  useEffect(() => {
    const fn = () => {
      setTableHeight(document.body.clientHeight - 60 - 180);
    };
    window.addEventListener('resize', fn);
    return () => {
      window.removeEventListener('resize', fn);
    };
  }, []);

  const handleSort = async (index, afterIndex) => {
    try {
      const items = produce(data, (draft) => {
        const item = draft.splice(index, 1);
        draft.splice(afterIndex, 0, item[0]);
      });
      setData(items);
      await restApi.put(`/estimates/categories`, { ids: items?.map((v) => v.id) });
    } catch (e) {
      alert(e?.response?.data?.message || '서버와 연결이 올바르지 않습니다.');
    }
    await dispatch(getEstimateCategories());
  };

  const navigate = useNavigate();

  return (
    <div>
      <PageHeader className="site-page-header" title="공종 카테고리" subTitle={'설정'} />
      <div style={{ position: 'relative' }}>
        <DragSortingTable
          rowKey={({ id }) => id}
          dataSource={data}
          columns={columns}
          pagination={{
            total: data?.total_elements,
            pageSize: pageSize,
            pageSizeOptions: data?.total_elements >= 500 ? ['10', '20', '30', '50', '100'] : ['10', '20', '30', '50'],
            onShowSizeChange: (current, size) => setPageSize(size),
            showSizeChanger: true,
          }}
          // 데이터 받아오는 쪽에서 page 쿼리값 바뀌는거 까지 해야함
          emptyText={'공종, 구분을 선택해주세요.'}
          onSort={handleSort}
          onRowClick={(record) => {
            navigate(`/docs/estimates/category/${record.id}`);
            console.log('record', record);
          }}
        />
      </div>
    </div>
  );
};

export default SettingsEstimateCategoriesIndex;
