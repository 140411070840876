import { getAPIHost } from '../apis';

export const getClientWidth = () => {
  const w = document.body.clientWidth;
  let aside = w >= 1024 ? 240 : 0;
  return w - aside - 40;
};

export const comma = (x) => {
  return (x ?? 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const parseImageUri = (id, w, h) => {
  const uri = `${getAPIHost()}/files/${id}`;
  const params = [];
  if (w) params.push(`w=${w}`);
  if (h) params.push(`h=${h}`);
  return uri + (!params?.length ? '' : `?${params.join('&')}`);
};

const expendCategory = (children) => {
  let items = [];
  children?.map((item) => {
    items.push({
      code: item.code,
      label: item.label,
    });
    if (item.children) {
      items = items.concat(expendCategory(item.children));
    }
  });
  return items;
};

export const parseCategory = (categories, value, separator) => {
  const keys = [];
  for (let i = 1; i <= Math.floor(value.length / 3); i++) {
    keys.push(value.substring(0, i * 3));
  }

  const c = categories.reduce((a, c) => {
    // a[c.code] = c;

    a[c.code] = {
      code: c.code,
      label: c.label,
    };
    if (c.children) {
      expendCategory(c.children).forEach((item) => {
        a[item.code] = {
          code: item.code,
          label: item.label,
        };
      });
    }

    return a;
  }, {});

  if (separator && typeof separator === 'string') {
    return keys?.map((v) => c[v]?.label)?.join(separator);
  } else {
    return keys.map((v, i) => (
      <>
        {c[v]?.label}
        {i !== keys?.length - 1 && separator}
      </>
    ));
  }
};
