import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Card, Checkbox, Select, Space, Table, Tabs } from 'antd';
import { restApi } from '../../apis';
import produce from 'immer';
import { POSITION_LABELS } from '../../common/define';
import ProductChoiceModalView from './ProductChoiceModalView';

const EstimateModal = ({ type, value, onChange, estimateTpls }) => {
  const [refStyleId, setRefStyleId] = useState();
  const [refStyles, setRefStyles] = useState([]);
  const [extraIds, setExtraIds] = useState([]);

  const [data, setData] = useState({
    estimate_apply: value,
  });

  useEffect(() => {
    setData({ estimate_apply: value });
  }, [value]);

  const [products, setProducts] = useState({});
  const [productModal, setProductModal] = useState();

  const pids = data?.estimate_apply
    ?.filter((v) => v.products?.length)
    ?.reduce((a, c) => a.concat(c.products), [])
    ?.map((v) => v.product_id);

  const loadData = useCallback(async (ids) => {
    console.log('loadData', ids);
    const { data } = await restApi.get(`/products`, { params: { ids, limit: ids.length } });
    setProducts((x) => ({
      ...x,
      ...data.items?.reduce((a, c) => {
        a[String(c.id)] = c;
        return a;
      }, {}),
    }));
  }, []);

  useEffect(() => {
    const ids = pids
      ?.concat(extraIds)
      ?.map((v) => String(v))
      ?.filter((pid) => !Object.keys(products)?.includes(pid));
    if (ids?.length) {
      loadData(ids).catch(console.warn);
    }
  }, [products, pids, extraIds]);

  const handleSearch = async (newValue) => {
    if (newValue) {
      const { data } = await restApi.get(`/magazines/sections`, { params: { keyword: newValue } });
      setRefStyles(data.items);
    } else {
      setData([]);
    }
  };

  const handleChange = (newValue) => {
    setRefStyleId(newValue);
  };

  const options = refStyles.map((d) => (
    <Select.Option key={d.id}>
      {d.magazine?.title} - [{d.order_index}] {d.title}
    </Select.Option>
  ));
  const handleLoadData = async () => {
    const { data: resp } = await restApi.get(`/magazines/sections/${refStyleId}`);
    setData((x) => ({
      ...x,
      estimate_apply: resp?.estimate_apply?.filter((v) => type === v.element_type) ?? [],
    }));
  };

  const tpls = useMemo(() => {
    return (estimateTpls ?? []).filter((v) => {
      if (!v.selectable) return false;
      if (v?.element_types?.includes('_all')) return true;

      if (v?.element_types?.includes(type)) return true;
      return false;
    });
  }, [estimateTpls, type]);

  const tabs = useMemo(() => {
    const mainCategories = Object.values(
      tpls?.reduce((a, c) => {
        if (!a[c.main_category.id]) {
          a[c.main_category.id] = c.main_category;
        }
        return a;
      }, {}),
    );
    return mainCategories;
  }, [tpls]);

  const columns = useMemo(() => {
    const render = (eType) => (code, row) => {
      if (!row.element_types?.includes(eType)) return '';
      const fn = (x) => x.tpl_id === row.id && x.element_type === eType;
      const value = data.estimate_apply?.find(fn);
      if (row?.use_product) {
        const handleClick = () => {
          setProductModal({
            row,
            tpl_id: row.id,
            element_types: [eType],
            products: value?.products ?? [],
          });
        };

        return (
          <Button type={!value ? undefined : 'primary'} onClick={handleClick} size={'small'}>
            제품관리
          </Button>
        );
      }

      const handleClick = () => {
        const body = produce((draft) => {
          if (!draft.estimate_apply) {
            draft.estimate_apply = [];
          }
          if (value) {
            const ix = draft.estimate_apply?.findIndex(fn);
            if (ix >= 0) draft.estimate_apply?.splice(ix, 1);
          } else {
            draft.estimate_apply.push({
              tpl_id: row.id,
              element_type: eType,
              active: true,
            });
          }
        });
        setData(body);
      };
      return <Checkbox checked={!!value} onClick={handleClick} />;
    };

    const c = [
      {
        key: 'side_category',
        dataIndex: 'side_category',
        title: '부분공사',
        render: (v) => v?.name,
        width: 130,
      },
      {
        key: 'name',
        dataIndex: 'name',
        title: '자제',
        minWidth: 200,
      },
    ];

    c.push({
      key: 'use_product_' + type,
      dataIndex: 'use_product',
      title: POSITION_LABELS[type],
      align: 'center',
      width: 100,
      render: render(type),
    });

    return c;
  }, [data.estimate_apply, type, products]);

  const handleProductSubmit = () => {
    setData((draft) => {
      const output = { ...draft };
      const apply = draft.estimate_apply?.filter(
        (v) => v.tpl_id !== productModal.tpl_id || !productModal.element_types?.includes(v.element_type),
      );
      if (productModal.products?.length) {
        output.estimate_apply = (apply ?? []).concat(
          productModal.element_types?.map((element_type) => ({
            tpl_id: productModal.tpl_id,
            element_type,
            active: true,
            products: productModal.products,
          })),
        );
      } else {
        output.estimate_apply = apply;
      }
      return output;
    });
    setProductModal(undefined);
  };

  return (
    <div>
      <Card>
        <Space>
          <Select
            style={{ width: 400 }}
            showSearch
            value={refStyleId}
            placeholder={'스타일 검색'}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={handleSearch}
            onChange={handleChange}
          >
            {options}
          </Select>
          <Button onClick={handleLoadData}>불러오기</Button>
        </Space>
      </Card>

      <Card style={{ marginTop: 20 }}>
        <Tabs>
          {tabs?.map((tab, i) => {
            const dataSource = tpls?.filter((v) => v?.main_category?.id === tab.id);
            return (
              <Tabs.TabPane key={tab.id} tab={tab.name}>
                <Table dataSource={dataSource} columns={columns} pagination={false} />
              </Tabs.TabPane>
            );
          })}
        </Tabs>
      </Card>

      <ProductChoiceModalView
        onOk={handleProductSubmit}
        data={productModal}
        setData={setProductModal}
        products={products}
        setExtraIds={setExtraIds}
        image={data.image}
      />
      <div style={{ textAlign: 'right' }}>
        <Button
          type={'primary'}
          onClick={() => {
            onChange(data.estimate_apply);
          }}
        >
          저장
        </Button>
      </div>
    </div>
  );
};

export default EstimateModal;
