import React, { useState } from 'react';
import { Button, Form, Input } from 'antd';
import styles from './Login.module.less';
import { restApi, setToken } from '../../apis';
import { useDispatch } from 'react-redux';
import { me } from '../../data/auth';

const Login = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const handleSubmit = async (data) => {
    if (loading) return;
    try {
      setLoading(true);

      const requestBody = {
        ...data,
        grant_type: 'password',
      };
      const { data: responseData } = await restApi.post(`/auth/login`, requestBody);
      setToken(responseData);
      await dispatch(me());
    } catch (e) {
      console.warn(e);
    }
    setLoading(false);
  };

  return (
    <div className={styles.login_wrapper}>
      <Form onFinish={handleSubmit}>
        <h2>LOGIN</h2>
        <Form.Item noStyle name={'username'}>
          <Input prefix={'아이디'} className={styles.input} allowClear />
        </Form.Item>
        <Form.Item noStyle name={'password'}>
          <Input.Password prefix={'비밀번호'} className={styles.input} allowClear />
        </Form.Item>
        <Button block className={styles.submit} type={'primary'} htmlType={'submit'} loading={loading}>
          로그인
        </Button>
      </Form>
    </div>
  );
};

export default Login;
