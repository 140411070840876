import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import React, { useEffect, useState } from 'react';
import { Button, Descriptions, Input, PageHeader, Space, Upload } from 'antd';
import { getAPIHost, restApi } from '../../../apis';
import { UploadOutlined } from '@ant-design/icons';
import { useParams } from 'react-router';
import { parseImageUri } from '../../../common/utils';

const ContentPopupCreate = () => {
  const route = useParams();
  const [data, setData] = useState({
    text: '',
    link: '',
    image_id: undefined,
    order_index: 0,
    active: true,
  });

  const id = route?.id;

  useEffect(() => {
    if (route.id === 'create') return;
    const loadData = async () => {
      const { data } = await restApi.get(`/homes/banners/${route.id}`);
      setData({
        text: data.text,
        link: data.link,
        image_id: data.image_id,
        order_index: data.order_index,
        active: true,
        type: 'POPUP',
      });
    };
    loadData().catch(console.warn);
  }, [route.id]);

  const handleDeletePost = async () => {
    if (!window.confirm('정말 삭제하시겠습니까?')) return;

    await restApi.delete(`/homes/banners/${route.id}`);
    alert('삭제되었습니다.');
    window.location.replace('/contents/popups');
  };

  const handleCreatePost = async () => {
    if (route.id === 'create') {
      await restApi.post(`/homes/banners`, {
        ...data,
        type: 'POPUP',
      });
    } else {
      await restApi.put(`/homes/banners/${route.id}`, {
        ...data,
      });
    }
    alert('저장되었습니다.');
    window.location.replace('/contents/popups');
  };

  return (
    <div>
      <PageHeader className="site-page-header" title={route.id === 'create' ? '팝업 작성' : '팝업 수정'} />
      <Descriptions bordered>
        <Descriptions.Item label={'이미지'} span={4} className="nopadding">
          <Upload
            action={`${getAPIHost()}/files`}
            method={'PUT'}
            name={'filedata'}
            onChange={(info) => {
              if (info.file.status !== 'done') return;
              setData((x) => ({ ...x, image_id: info.file.response.id }));
            }}
          >
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
          {data.image_id && <img src={parseImageUri(data.image_id)} style={{ maxWidth: 200 }} />}
        </Descriptions.Item>
        <Descriptions.Item label={'내용'} span={4} className="nopadding">
          <Input.TextArea
            value={data.text}
            onChange={(e) => {
              const text = e.target.value;
              setData((x) => ({ ...x, text }));
            }}
            bordered={false}
            placeholder="제목을 입력해주세요"
            style={{ padding: '10px 20px' }}
          />
        </Descriptions.Item>
        <Descriptions.Item label={'링크'} span={4} className="nopadding">
          <Input
            value={data.link}
            onChange={(e) => {
              const link = e.target.value;
              setData((x) => ({ ...x, link }));
            }}
            bordered={false}
            placeholder="주소를 입력해주세요"
            style={{ padding: '10px 20px' }}
          />
        </Descriptions.Item>
        <Descriptions.Item label={'순서'} span={4} className="nopadding">
          <Input
            value={data.order_index}
            onChange={(e) => {
              const order_index = e.target.value;
              setData((x) => ({ ...x, order_index }));
            }}
            bordered={false}
            placeholder="0~100"
            style={{ padding: '10px 20px' }}
          />
        </Descriptions.Item>
      </Descriptions>
      <div style={{ justifyContent: 'flex-end', marginTop: 10 }}>
        <Space>
          <Button onClick={handleCreatePost} size={'large'}>
            저장
          </Button>
          {!!id && id !== 'create' && (
            <Button danger onClick={handleDeletePost} size={'large'}>
              삭제
            </Button>
          )}
        </Space>
      </div>
    </div>
  );
};

export default ContentPopupCreate;
