import React from 'react';
import styles from './Aside.module.less';

const AuthAside = () => (
  <>
    <h2 className={styles.h2}>바바 CMS</h2>
    <p className={styles.p}>
      오직 당신을 위한 바바 플랫폼, 그 첫걸음
      <br />
      고객들과의 소통과 견적관리를 도와줍니다.
      <br />
      지금 고객을 위한 새로운 채널, 바바로 시작하세요!
    </p>
  </>
);

export default AuthAside;
