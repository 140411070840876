import { shallowEqual, useSelector } from 'react-redux';
import { useMemo } from 'react';

const useTreeData = () => {
  const productCategories = useSelector((s) => s.common?.productCategories, shallowEqual);

  const treeData = useMemo(() => {
    if (!productCategories) return [];
    const parseItem = (d) => {
      return {
        value: d.code,
        label: d.label,
        children: !d.children?.length ? undefined : d.children?.map((v) => parseItem(v)),
      };
    };

    return productCategories.map((v) => parseItem(v));
  }, [productCategories]);

  return { productCategories, treeData };
};

export default useTreeData;
