import React, { useEffect, useMemo, useState } from 'react';
import { Button, PageHeader, Table } from 'antd';
import SearchForm from '../../../components/datalist/SearchForm';
import { restApi } from '../../../apis';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import qs from 'query-string';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { getClientWidth } from '../../../common/utils';

// const LIMIT = 50;
const COLUMNS = [
  {
    title: '번호',
    dataIndex: 'id',
    align: 'center',
    width: 90,
    render: (v, __, index) => {
      return v;
    },
  },
  {
    title: '아파트명',
    dataIndex: 'name',
    width: 200,
    render: (v, __, index) => {
      return v;
    },
  },
  {
    title: '주소',
    dataIndex: 'address',
    render: (v, r) => {
      return (
        <>
          <div>{r.address}</div>
          <small>{r.road_address}</small>
        </>
      );
    },
  },
  {
    title: '평형개수',
    dataIndex: 'count',
    width: 90,
    align: 'center',
    render: (v) => {
      return v ?? 0;
    },
  },
  {
    title: '사용여부',
    dataIndex: 'active',
    width: 90,
    align: 'center',
    render: (v, __, index) => {
      return v ? <CheckOutlined style={{ color: '#068600' }} /> : <CloseOutlined style={{ color: '#db2929' }} />;
    },
  },
  {
    title: '',
    dataIndex: 'id',
    key: 'more',
    width: 120,
    align: 'center',
    render: (id) => {
      return (
        <Link to={`/settings/apartments/${id}`}>
          <Button size={'small'}>자세히보기</Button>
        </Link>
      );
    },
  },
];

const SettingsApartmentsIndex = () => {
  const location = useLocation();
  const query = React.useMemo(() => qs.parse(location?.search), [location?.search]);
  const [page, setPage] = useState(parseInt(qs.parse(location.search)?.page || '0', 10));
  const [data, setData] = React.useState();
  const [pageSize, setPageSize] = useState(20);
  const navigate = useNavigate();
  // query 바뀔 때 마다 데이터 조회
  const loadData = React.useCallback(async () => {
    const params = {
      ...query,
      limit: pageSize,
    };
    const { data } = await restApi.get('/apartments', { params });
    setData(data);
  }, [query, pageSize]);

  React.useEffect(() => {
    loadData().catch(console.warn);
  }, [loadData]);

  const [columns, tableWidth] = useMemo(() => {
    const o = [...COLUMNS];

    const width = o.reduce((a, c) => a + (c.width || c.minWidth || 300), 0);
    return [o, width];
  }, []);

  const [tableScroll, setTableScroll] = useState();
  useEffect(() => {
    const handle = () => {
      if (tableWidth < getClientWidth()) {
        setTableScroll(undefined);
      } else {
        setTableScroll({ x: tableWidth });
      }
    };

    handle();
  }, [tableWidth]);

  // 테이블 페이지네이션
  // const pagination = {
  //   position: ['bottomCenter'],
  //   total: data?.total_elements,
  //   pageSize: LIMIT,
  //   current: data?.page + 1,
  //   onChange: (p) => setExtra({ page: p - 1 }),
  // };
  const searchField = [
    {
      width: 0.5,
      label: '종류',
      type: 'select',
      key: 'type',
      items: [
        { label: '아파트', value: 'APT' },
        { label: '오피스텔', value: 'OPST' },
      ],
      submit: true,
    },
    {
      width: 0.5,
      label: '검색어',
      type: 'input',
      key: 'keyword',
    },
  ];

  const [tableHeight, setTableHeight] = useState(document.body.clientHeight - 60 - 180);
  useEffect(() => {
    const fn = () => {
      setTableHeight(document.body.clientHeight - 60 - 180);
    };
    window.addEventListener('resize', fn);
    return () => {
      window.removeEventListener('resize', fn);
    };
  }, []);

  // const onRow = (record, rowIndex) => {
  //   return {
  //     onClick: (event) => {
  //       navigate(`/settings/apartments/${record.id}`);
  //     },
  //   };
  // };
  return (
    <div>
      <PageHeader className="site-page-header" title="아파트 정보" subTitle={'설정'} />
      <SearchForm fields={searchField} extra={{ page }} />
      <div style={{ position: 'relative' }}>
        <Table
          rowKey={({ id }) => id}
          scroll={{ y: tableHeight }}
          dataSource={data?.items}
          columns={columns}
          pagination={{
            current: page + 1,
            total: data?.total_elements,
            pageSize: pageSize,
            pageSizeOptions: data?.total_elements > 500 ? ['10', '20', '30', '50', '100'] : ['10', '20', '30', '50'],
            onShowSizeChange: (current, size) => setPageSize(size),
            showSizeChanger: true,
          }}
          onChange={(e) => {
            setPage(e.current - 1);
          }}
          // onRow={onRow}
        />
      </div>
    </div>
  );
};

export default SettingsApartmentsIndex;
