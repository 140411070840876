import { Button, Descriptions, Divider, PageHeader, Switch, Typography, Tabs, Space, Modal } from 'antd';
import React, { useState } from 'react';
import { comma } from '../../../../common/utils';
import ConstructionTabs from '../../../construction/tab';
import styles from './style.module.less';
export default function QuotationApplicationDetails() {
  const { Title, Paragraph, Text } = Typography;
  const [isOpen, setIsOpen] = useState(false);

  const handleCancel = () => {
    setIsOpen(false);
  };
  return (
    <div>
      <PageHeader title="공사도움받기 신청" subTitle="제안서 작성" />
      <div className={`${styles.infoWrap}`}>
        <div className={`${styles.infoContainer}`}>
          <div className={`${styles.dflex}`}>
            <Title level={2}>주택명</Title>
            <Paragraph>개봉 푸르지오</Paragraph>
          </div>
          <div className={`${styles.dflex}`}>
            <Title level={2}>면적</Title>
            <Paragraph>34PY</Paragraph>
          </div>
          <div className={`${styles.dflex}`}>
            <Title level={2}>현장주소</Title>
            <Paragraph>서울시 구로구 남부순환로 775</Paragraph>
          </div>
        </div>
        <div>
          <Button type="primary">적용스타일보기</Button>
        </div>
      </div>
      <div className={styles.switchWrap}>
        <Text>공종별 보기</Text>
        <Switch />
        <Text>공간별 보기</Text>
      </div>
      <Space className={styles.sum}>
        <Space>
          <Text>총 견적 금액</Text>
          <Text style={{ fontSize: 20, fontWeight: 'bold' }}>{comma(33950000)}원</Text>
        </Space>
        <Space>
          <Text>바닥공사소계</Text>
          <Text style={{ fontSize: 20, fontWeight: 'bold' }}>{comma(2120000)}원</Text>
        </Space>
      </Space>
      <ConstructionTabs />

      <Space style={{ position: 'fixed', bottom: 10, left: '50%' }}>
        <Button
          onClick={() => {
            setIsOpen(true);
          }}
          type="primary"
        >
          임시저장
        </Button>
        <Button type="primary">제안서 발송</Button>
      </Space>
      <Modal
        width={600}
        visible={isOpen}
        title="임시저장"
        onCancel={handleCancel}
        footer={[
          <Button key="back" type="primary" onClick={handleCancel}>
            확인
          </Button>,
        ]}
      >
        <Text>
          제안서를 임시저장하였습니다. 임시저장한 제안서는 제안한 견적에 보관(제안대기중)됩니다. <br /> 제안 대기중
          항목을 클릭하여 제안서 작성을 진행할 수 있습니다.
        </Text>
      </Modal>
    </div>
  );
}
