import React, { useEffect, useState } from 'react';
import { Button, Divider, Layout, PageHeader, Pagination, Select, Table, Typography } from 'antd';
import SearchForm from '../../../components/datalist/SearchForm';

// import styles from './style.module.less';
import { comma } from '../../../common/utils';
import dayjs from 'dayjs';
import { Link, useNavigate } from 'react-router-dom';

const data = [
  {
    id: 1,
    status: '열람',
    apt_name: '개봉 푸르지오',
    address: '서울시 구로구 남부순환로 775',
    area_size: '34PY',
    consumer: {
      name: '홍길동',
      phone: '010-1234-5678',
    },
    total_count: 3,
    memo_count: 1,
    total_price: 3400,
    created_at: new Date(),
    due_date: {
      remaining_period: '1',
      deadline: new Date(),
    },
    deadline: new Date(),
    remainBidDate: null,
    constructionDate: null,
  },
];

const columns = [
  {
    title: '번호',
    dataIndex: 'id',
    key: 'id',
    width: 70,
    align: 'center',
  },
  //   {
  //     title: '상태',
  //     dataIndex: 'status',
  //     key: 'status',
  //     width: 70,
  //     align: 'center',
  //   },
  {
    title: '주택명',
    dataIndex: 'apt_name',
    key: 'apt_name',
    width: 200,
    sorter: true,
    // render: (d, row) => <Link to={`/quotation/application/${row.id}`}>{d}</Link>,
  },
  {
    title: '평수',
    dataIndex: 'area_size',
    key: 'area_size',
    width: 100,
    align: 'center',
    sorter: true,
  },
  {
    title: '주소',
    dataIndex: 'address',
    width: 200,
    key: 'address',
    sorter: true,
  },
  {
    title: '의뢰 고객',
    dataIndex: 'consumer',
    key: 'consumer',
    width: 200,
    align: 'center',
    sorter: true,
    render: (value) => `${value.name} / ${value.phone}`,
  },
  {
    title: '소비자 예상견적가',
    dataIndex: 'total_price',
    key: 'total_price',
    width: 200,
    align: 'center',
    sorter: true,
    render: (value) => `${comma(value)}만원`,
  },
  //   {
  //     title: '전송견적수',
  //     dataIndex: 'total_count',
  //     key: 'total_count',
  //     width: 110,
  //     align: 'center',
  //   },
  //   {
  //     title: '메모수',
  //     dataIndex: 'memo_count',
  //     key: 'memo_count',
  //     width: 70,
  //     align: 'center',
  //   },
  {
    title: '공사도움받기 요청일시',
    dataIndex: 'created_at',
    key: 'created_at',
    width: 200,
    align: 'center',
    sorter: true,
    render: (value) => dayjs(value).format('YYYY.MM.DD HH:mm:ss'),
  },
  {
    title: '잔여기간/마감일',
    dataIndex: 'due_date',
    key: 'due_date',
    width: 200,
    align: 'center',
    sorter: true,
    render: (value) => `${dayjs(value.remaining_period).format('D')}일 / ${dayjs(value.deadline).format('YYYY.MM.DD')}`,
  },
  {
    title: '공사시작일(예정)',
    dataIndex: 'updated_at',
    key: 'updated_at',
    width: 200,
    align: 'center',
    sorter: true,
    render: (value) => dayjs(value).format('YYYY.MM.DD'),
  },
  //   {
  //     title: '',
  //     dataIndex: 'id',
  //     key: 'detail',
  //     width: 100,
  //     align: 'center',
  //   },
];

const QuotationApplicationIndex = () => {
  //   const { data, footer } = useOngoing();
  //   const { Paragraph } = Typography;
  const navigate = useNavigate();
  const searchField = [
    // {
    //   type: 'select',
    //   width: 0.25,
    //   label: '공사기간',
    //   key: 'range_type',
    //   items: [
    //     { value: 'created_at', label: '작성일' },
    //     { value: 'constructionDate', label: '공사 예정일' },
    //   ],
    // },
    {
      type: 'inputrange',
      width: 0.5,
      label: '비용',
      key: 'cost',
      keys: ['start_cost', 'end_cost'],
    },
    {
      type: 'selectrange',
      width: 0.5,
      label: '지역',
      key: 'area',
    },
    {
      type: 'daterange',
      width: 0.25,
      label: '공사기간',
      key: 'date',
      keys: ['start_at', 'end_at'],
    },
    {
      type: 'radiobutton',
      width: 0.5,
      key: 'range',
      items: [
        { value: '1m', label: '1개월' },
        { value: '3m', label: '3개월' },
        { value: '6m', label: '6개월' },
      ],
    },
    // {
    //   type: 'select',
    //   width: 0.5,
    //   label: '공사구분',
    //   key: 'construction_type',
    // },
    // {
    //   type: 'select',
    //   width: 0.5,
    //   label: '진행상태',
    //   key: 'status',
    // },
    // {
    //   type: 'select',
    //   width: 0.5,
    //   label: '검색조건',
    //   key: 'keyword_type',
    // },
    // {
    //   type: 'input',
    //   width: 0.5,
    //   label: '검색어',
    //   key: 'keyword',
    // },
  ];
  const [tableHeight, setTableHeight] = useState(document.body.clientHeight - 60 - 180);
  useEffect(() => {
    const fn = () => {
      setTableHeight(document.body.clientHeight - 60 - 180);
    };
    window.addEventListener('resize', fn);
    return () => {
      window.removeEventListener('resize', fn);
    };
  }, []);

  const onRow = (record, rowIndex) => {
    return {
      onClick: (event) => {
        navigate(`/quotation/application/${record.id}`);
      },
    };
  };
  return (
    <div>
      <PageHeader title="공사 도움받기 신청" />
      <SearchForm fields={searchField} />
      {/* <Divider /> */}

      {/*TODO Table 헤더 (Limit Select) 등 api 연동 하면서 CommTable로 변경하며 작업*/}
      {/*TODO api 연동할 때 CommTable 로 변경*/}
      {/*TODO 견적서 추가버튼은 api 연동하면서 CommTable로 공통화 하며 UI수정*/}

      <Table
        columns={columns}
        dataSource={data}
        pagination={{
          current: 1,
          total: 100,
          pageSize: 20,
          pageSizeOptions: data?.total_elements >= 500 ? ['10', '20', '30', '50', '100'] : ['10', '20', '30', '50'],
          //   onShowSizeChange: (current, size) => setPageSize(size),
          showSizeChanger: true,
        }}
        onRow={onRow}
      />
      {/* <div className={styles.btnContainer}> */}
      {/* <Button>견적서 추가</Button> */}
      {/* </div> */}
    </div>
  );
};

export default QuotationApplicationIndex;
