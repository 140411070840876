import React, { useEffect, useRef } from 'react';
import { Button, Checkbox, Col, DatePicker, Form, Input, Radio, Row, Select, Space, TreeSelect } from 'antd';
import styles from './SearchForm.module.less';
import { useLocation, useNavigate } from 'react-router-dom';
import * as qs from 'query-string';
import moment from 'moment';

const SearchForm = ({ fields, extra, rightDeco }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const form = useRef();
  const [inputs, setInputs] = React.useState({});
  const numberWithCommas = (value) => {
    if (!value) return;
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const removeCommas = (value) => {
    if (!value) return;
    return value.replace(/,/g, '');
  };

  const handleSubmit = (data) => {
    const args = { ...extra };
    for (const key of Object.keys(data).filter((v) => !!data[v])) {
      const field = fields.find((v) => v.key === key);
      if (field.type === 'datepicker') {
        args[key] = data[key].format('YYYY-MM-DD');
      } else if (field.type === 'daterange') {
        for (let i = 0; i < field.keys?.length; i++) {
          const subkey = field.keys[i];
          args[subkey] = data[key][i].format('YYYY-MM-DD');
        }
      } else {
        args[key] = data[key];
      }
    }
    navigate('?' + qs.stringify(args), { replace: true });
  };

  useEffect(() => {
    if (form.current) {
      form.current.submit();
    }
  }, [JSON.stringify(extra)]);

  useEffect(() => {
    const data = {};
    const args = qs.parse(location?.search);
    for (const key of Object.keys(args).filter((v) => !!args[v])) {
      const field = fields.find((v) => v.key === key);
      if (!field) continue;
      if (field.type === 'datepicker') {
        data[key] = moment(args[key]);
      } else if (field.type === 'daterange') {
        const o = [];
        for (let i = 0; i < field.keys?.length; i++) {
          const subkey = field.keys[i];
          o[i] = moment(args[subkey]);
        }
        data[key] = o;
      } else {
        data[key] = args[key];
      }
    }
    form.current.setFieldsValue(data);
  }, [location?.search]);
  const onChangeInputRange = React.useCallback(
    (event) => {
      const { name, value } = event.target;
      // 기획서 확인해보니 inputrange에서 숫자가 아닌걸 받는 경우가 없어 숫자아니면 리턴
      if (value === '') {
        // 공백은 허용해야지...
        setInputs({ ...inputs, [name]: '' });
        return;
      }
      if (!/^[0-9,]+$/.test(value)) return;
      // 인테리어 견적 금액 특성상 기본 백만원 이상이기 때문에 가독성을 위해 콤마 추가
      setInputs({ ...inputs, [name]: numberWithCommas(removeCommas(value)) });
    },
    [inputs],
  );

  return (
    <div className={styles.search_form}>
      <Form onFinish={handleSubmit} ref={form}>
        <Row
          gutter={[40, 10]}
          //  style={{ display: 'flex ' }}
        >
          {fields?.map((field, index) => {
            const onChange = field.submit ? () => form.current.submit() : undefined;
            return (
              <Col
                span={field.type === 'button' ? 4 : 10}
                // xs={12}
                // sm={8}
                // lg={24 * (field?.width ?? 1)}
                key={`${index}`}
              >
                <Form.Item name={field.key} label={field.label}>
                  {field.type === 'radio' ? (
                    <Radio.Group onChange={onChange}>
                      {field?.items?.map((item) => (
                        <Radio value={item.value} key={String(item.value)}>
                          {item.label}
                        </Radio>
                      ))}
                    </Radio.Group>
                  ) : field.type === 'radiobutton' ? (
                    <Radio.Group onChange={onChange}>
                      {field?.items?.map((item) => (
                        <Radio.Button value={item.value} key={String(item.value)}>
                          {item.label}
                        </Radio.Button>
                      ))}
                    </Radio.Group>
                  ) : field.type === 'checkbox' ? (
                    <Checkbox.Group onChange={onChange}>
                      {field?.items?.map((item) => (
                        <Checkbox value={item.value} key={String(item.value)}>
                          {item.label}
                        </Checkbox>
                      ))}
                    </Checkbox.Group>
                  ) : field.type === 'datepicker' ? (
                    <DatePicker />
                  ) : field.type === 'daterange' ? (
                    <DatePicker.RangePicker />
                  ) : field.type === 'select' ? (
                    <Select onChange={onChange} allowClear disabled={field.disabled}>
                      {field?.items?.map((item) => (
                        <Select.Option value={item.value} key={String(item.value)}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>
                  ) : field.type === 'selectrange' ? (
                    <div className={styles.select_range}>
                      <Select onChange={onChange} allowClear disabled={field.disabled}>
                        {field?.items?.map((item) => (
                          <Select.Option value={item.value} key={String(item.value)}>
                            {item.label}
                          </Select.Option>
                        ))}
                      </Select>
                      <Select onChange={onChange} allowClear disabled={field.disabled}>
                        {field?.items?.map((item) => (
                          <Select.Option value={item.value} key={String(item.value)}>
                            {item.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </div>
                  ) : field.type === 'tree_select' ? (
                    <TreeSelect treeData={field.treeData} onChange={onChange} allowClear disabled={field.disabled} />
                  ) : field.type === 'inputrange' ? (
                    <Input.Group compact className={styles.input_range}>
                      <Input
                        allowClear
                        name={field.keys[0]}
                        value={inputs[field.keys[0]]}
                        className={styles.input}
                        onChange={onChangeInputRange}
                      />
                      ~
                      <Input
                        allowClear
                        name={field.keys[1]}
                        value={inputs[field.keys[1]]}
                        className={styles.input}
                        onChange={onChangeInputRange}
                      />
                    </Input.Group>
                  ) : (
                    <Input allowClear />
                  )}
                </Form.Item>
              </Col>
            );
          })}
          <Col>
            <div className={styles[`move-down`]}>
              <Space>
                <Button htmlType={'submit'} type={'primary'}>
                  검색
                </Button>
                {rightDeco}
              </Space>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default React.memo(SearchForm);
