import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  PageHeader,
  Row,
  Select,
  Space,
  Switch,
  Upload,
} from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { getAPIHost, restApi } from '../../../apis';
import produce from 'immer';
import { POSITION_LABELS } from '../../../common/define';
import { parseImageUri } from '../../../common/utils';
import EstimateModal from '../../../components/magazine/EstimateModal';
import { shallowEqual, useSelector } from 'react-redux';

const uploadButton = (
  <div>
    <PlusOutlined />
    <div
      style={{
        marginTop: 8,
      }}
    >
      Upload
    </div>
  </div>
);

const SectionItem = ({ value, onChange, onRemove, onMove }) => {
  const items = Object.keys(POSITION_LABELS).map((v) => ({ key: v, label: POSITION_LABELS[v] }));

  const [isUrl, setUrl] = useState();
  const [uri, setUri] = useState();
  const [showModal, setShowModal] = useState();

  const handleUploadURL = async () => {
    const { data } = await restApi.post(`/files`, { uri });
    onChange(
      produce(value, (draft) => {
        draft.image = data.id;
      }),
    );
    setUri(undefined);
    setUrl(false);
  };

  const { estimateTpls } = useSelector((s) => s.common, shallowEqual);

  return (
    <div style={{ padding: '10px 10px', borderBottom: '1px solid #ddd', background: '#fff', marginBottom: 10 }}>
      <div>
        <div style={{ marginBottom: 5, display: 'flex', flexDirection: 'row' }}>
          <Input
            placeholder={'제목'}
            value={value?.title}
            onChange={(e) => {
              const v = e.target.value;
              onChange(
                produce(value, (draft) => {
                  draft.title = v;
                }),
              );
            }}
          />
          <Select
            style={{ width: 180, marginLeft: 5 }}
            allowClear
            value={value.position}
            onChange={(v) => {
              onChange(
                produce(value, (draft) => {
                  draft.position = v;
                }),
              );
            }}
          >
            {items?.map((item) => (
              <Select.Option value={item.key}>{item.label}</Select.Option>
            ))}
          </Select>
        </div>
      </div>
      <div style={{ background: '#f7f7f7', minHeight: 200, position: 'relative' }}>
        <img src={parseImageUri(value.image)} style={{ width: '100%' }} />
        <div
          style={{
            position: 'absolute',
            display: 'flex',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Space>
            <Button onClick={() => setUrl((x) => !x)}>URL 입력</Button>
            <Upload
              action={`${getAPIHost()}/files`}
              method={'PUT'}
              name={'filedata'}
              onChange={(info) => {
                if (info.file.status !== 'done') return;
                onChange(
                  produce(value, (draft) => {
                    draft.image = info.file.response.id;
                  }),
                );
              }}
            >
              <Button>파일첨부</Button>
            </Upload>
          </Space>
        </div>
      </div>
      {isUrl && (
        <div style={{ marginTop: 10, display: 'flex', flexDirection: 'row' }}>
          <Input
            placeholder={'이미지 주소'}
            value={uri}
            onChange={(v) => {
              setUri(v.target.value);
            }}
          />
          <Button style={{ marginLeft: 5 }} onClick={handleUploadURL}>
            등록
          </Button>
        </div>
      )}
      <div style={{ marginBottom: 5, marginTop: 5 }}>
        <Input.TextArea
          placeholder={'내용'}
          value={value?.content}
          onChange={(e) => {
            const v = e.target.value;
            onChange(
              produce(value, (draft) => {
                draft.content = v;
              }),
            );
          }}
          autoSize={{ minRows: 3 }}
        />
      </div>
      <div>
        <Input
          prefix={'출처'}
          placeholder={'본인 컨텐츠일 경우 비워둬도 됩니다.'}
          value={value?.from}
          onChange={(e) => {
            const v = e.target.value;
            onChange(
              produce(value, (draft) => {
                draft.from = v;
              }),
            );
          }}
        />
      </div>
      <Row gutter={5} style={{ marginTop: 5 }}>
        <Col flex={1}>
          <Button block onClick={() => onMove(-1)}>
            위로
          </Button>
        </Col>
        <Col flex={1}>
          <Button block onClick={() => onMove(1)}>
            아래로
          </Button>
        </Col>
        <Col flex={1}>
          <Button
            type={'danger'}
            block
            onClick={() => {
              if (!window.confirm('정말 삭제하시겠습니까?')) return;
              onRemove?.();
            }}
          >
            삭제
          </Button>
        </Col>
        {!!value.position && (
          <Col flex={1}>
            <Button type={'primary'} block onClick={() => setShowModal(true)}>
              견적설정
            </Button>
            <Modal
              title={'견적 설정'}
              visible={showModal}
              footer={null}
              width={1200}
              onCancel={() => setShowModal(false)}
              maskClosable={false}
            >
              {!!showModal && (
                <EstimateModal
                  estimateTpls={estimateTpls}
                  type={value.position}
                  value={value?.estimate_apply}
                  onChange={(v) => {
                    const product_ids = v?.reduce((a, x) => a.concat(x.products?.map((vv) => vv.product_id)), []) ?? [];
                    onChange(
                      produce(value, (draft) => {
                        draft.estimate_apply = v;
                        draft.product_ids = product_ids;
                      }),
                    );
                    setShowModal(false);
                  }}
                />
              )}
            </Modal>
          </Col>
        )}
      </Row>
    </div>
  );
};

const CollectionsMagazinesDetails = () => {
  const params = useParams();
  const isCreate = params?.id === 'create';
  const sectionBody = useRef();

  const [data, setData] = useState({
    title: '',
    image: undefined,
    desc: '',
    args2: '',
    args3: '',
    args4: '',
    sections: [],
    active: true,
    tags: [],
  });
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    if (!isCreate) {
      const loadData = async () => {
        const { data } = await restApi.get(`/magazines/${params?.id}`);
        setData(data);
        if (data.image) {
          console.log(data.image, '!');
          setFileList([
            {
              uid: String(data.image),
              name: 'image.png',
              status: 'done',
              url: parseImageUri(data.image),
            },
          ]);
        }
      };
      loadData().catch(console.warn);
    }
  }, [isCreate, params?.id]);
  console.log('data', data);
  const [loading, setLoading] = useState(false);
  const [bodySize, setBodySize] = useState('M');

  const handleSubmit = async () => {
    setLoading(true);
    try {
      if (isCreate) {
        await restApi.post(`/magazines`, data);
        message.success('저장되었습니다.');
        window.history.back();
      } else {
        console.log('!!', data);
        await restApi.put(`/magazines/${params?.id}`, data);
        message.success('저장되었습니다.');
        window.history.back();
      }
    } catch (e) {
      console.warn(e);
    } finally {
      setLoading(false);
    }
  };

  const handleRemove = async () => {
    if (!window.confirm('삭제하시겠습니까?')) return;
    setLoading(true);
    try {
      await restApi.delete(`/magazines/${params.id}`);
      message.success('삭제되었습니다.');
      window.history.back();
    } catch (e) {
      console.warn(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        marginLeft: -20,
        marginRight: -20,
        overflow: 'hidden',
      }}
    >
      <div style={{ height: 48, overflow: 'hidden', paddingLeft: 20, paddingRight: 20 }}>
        <PageHeader className="site-page-header" title="스타일관리" subTitle="등록된 스타일를 관리합니다." />
      </div>
      <div
        style={{
          paddingLeft: 20,
          paddingRight: 20,
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row', height: 70, alignItems: 'center' }}>
          <div style={{ flex: 1 }}></div>
          <div>
            <Space>
              <Button
                onClick={() => {
                  setData(
                    produce((draft) => {
                      draft.sections.push({});
                    }),
                  );
                  setTimeout(() => {
                    sectionBody.current?.scrollIntoView({ behavior: 'smooth' });
                  }, 200);
                }}
              >
                섹션추가
              </Button>
              <Button.Group>
                <Button onClick={() => setBodySize('S')} type={bodySize == 'S' ? 'primary' : undefined}>
                  S
                </Button>
                <Button onClick={() => setBodySize('M')} type={bodySize == 'M' ? 'primary' : undefined}>
                  M
                </Button>
                <Button onClick={() => setBodySize('L')} type={bodySize == 'L' ? 'primary' : undefined}>
                  L
                </Button>
              </Button.Group>
              <Button onClick={handleSubmit} loading={loading} type={'primary'}>
                저장
              </Button>
              {!isCreate && (
                <Button onClick={handleRemove} type={'danger'}>
                  삭제
                </Button>
              )}
            </Space>
          </div>
        </div>

        <div style={{ flex: 1, overflow: 'hidden' }}>
          <Row gutter={20} style={{ height: '100%' }}>
            <Col xs={24} md={12} span={12}>
              <Form layout={'horizontal'}>
                <Row gutter={20}>
                  <Col span={24}>
                    <Form.Item label={'제목'} style={{ marginBottom: 20 }}>
                      <Input
                        value={data.title}
                        onChange={(e) => {
                          const v = e.target.value;
                          setData((x) => ({
                            ...x,
                            title: v,
                          }));
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label={'이미지'} style={{ marginBottom: 20 }}>
                      <Upload
                        listType="picture-card"
                        action={`${getAPIHost()}/files`}
                        method={'PUT'}
                        name={'filedata'}
                        fileList={fileList}
                        onChange={(info) => {
                          setFileList(info.fileList);
                          if (info.file.status !== 'done') return;
                          setData((x) => ({ ...x, image: info.file.response.id }));
                        }}
                      >
                        {fileList.length >= 1 ? null : uploadButton}
                      </Upload>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label={'설명'} style={{ marginBottom: 20 }}>
                      <Input.TextArea
                        style={{ height: 100 }}
                        value={data.desc}
                        onChange={(e) => {
                          const v = e.target.value;
                          setData((x) => ({
                            ...x,
                            desc: v,
                          }));
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label={'기간'} style={{ marginBottom: 20 }}>
                      <Select value={data.args2} onChange={(v) => setData((x) => ({ ...x, args2: v }))}>
                        <Select.Option value={'LT'}>5주이상 공시기간(롱텀)</Select.Option>
                        <Select.Option value={'5'}>5주 이하 공사기간</Select.Option>
                        <Select.Option value={'4'}>4주 이하 공사기간</Select.Option>
                        <Select.Option value={'3'}>3주 이하 공사기간</Select.Option>
                        <Select.Option value={'2'}>2주 이하 공사기간</Select.Option>
                        <Select.Option value={'1'}>1주 이하 공사기간</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label={'난이도'} style={{ marginBottom: 20 }}>
                      <Select value={data.args3} onChange={(v) => setData((x) => ({ ...x, args3: v }))}>
                        <Select.Option value={'1'}>특수 협력이 가능한 시공사가 시공 가능 합니다.</Select.Option>
                        <Select.Option value={'2'}>소수의 기술력을 갖춘 시공사들이 시공 합니다.</Select.Option>
                        <Select.Option value={'3'}>상위 50% 이상의 시공사들이 시공 합니다.</Select.Option>
                        <Select.Option value={'4'}>대부분의 시공사에서 시공 합니다.</Select.Option>
                        <Select.Option value={'5'}>모든 시공사에서 시공 가능합니다.</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label={'비용'} style={{ marginBottom: 20 }}>
                      <Select value={data.args4} onChange={(v) => setData((x) => ({ ...x, args4: v }))}>
                        <Select.Option value={'B'}>경제성을 중시한 스타일</Select.Option>
                        <Select.Option value={'S'}>대부분의 소비자들이 선택하는 스타일</Select.Option>
                        <Select.Option value={'P'}>하이엔드를 지향하는 스타일</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label={'태그'} style={{ marginBottom: 20 }}>
                      <Select
                        value={data?.tags}
                        onChange={(value) => setData((x) => ({ ...x, tags: value }))}
                        mode="tags"
                        allowClear
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label={'공개여부'} style={{ marginBottom: 20 }}>
                      <Switch checked={data?.active} onChange={(v) => setData((x) => ({ ...x, active: v }))} />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label={'추천여부'} style={{ marginBottom: 20 }}>
                      <Space>
                        <Switch checked={data?.is_master} onChange={(v) => setData((x) => ({ ...x, is_master: v }))} />
                        {data?.is_master && (
                          <InputNumber
                            value={data?.master_index}
                            onChange={(value) => {
                              setData((x) => ({ ...x, master_index: value }));
                            }}
                          />
                        )}
                      </Space>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
            <Col xs={24} md={12} span={12} style={{ height: '100%' }}>
              <div
                style={{
                  maxWidth: 1300,
                  background: '#eee',
                  border: '1px solid #ddd',
                  padding: 20,
                  height: '100%',
                  overflowY: 'scroll',
                }}
              >
                {data?.sections?.length <= 0 ? (
                  <div>섹션추가를 눌러서 내용을 입력해주세요.</div>
                ) : (
                  <div
                    style={{
                      margin: '0px auto',
                      maxWidth: bodySize == 'S' ? 320 : bodySize == 'M' ? 375 : bodySize == 'L' ? 414 : '100%',
                    }}
                  >
                    {data?.sections?.map((section, idx) => (
                      <SectionItem
                        onMove={(ix) => {
                          setData(
                            produce((draft) => {
                              const fromIndex = idx;
                              const toIndex = idx + ix;
                              console.log({ fromIndex, toIndex });
                              const [removed] = draft.sections.splice(fromIndex, 1);
                              draft.sections.splice(toIndex, 0, removed);
                            }),
                          );
                        }}
                        value={section}
                        onChange={(section) => {
                          setData(
                            produce((draft) => {
                              draft.sections[idx] = section;
                            }),
                          );
                        }}
                        onRemove={() => {
                          setData(
                            produce((draft) => {
                              draft.sections.splice(idx, 1);
                            }),
                          );
                        }}
                      />
                    ))}
                  </div>
                )}
                <div ref={sectionBody}></div>
              </div>
            </Col>
          </Row>
        </div>

        {/*<div style={{ maxWidth: 1300 }}>*/}

        {/*</div>*/}
      </div>
      {/*<div style={{ position: 'absolute', bottom: 0, height: '90%', background: '#fff', width: 200, right: 20 }}></div>*/}
    </div>
  );
};

export default CollectionsMagazinesDetails;
